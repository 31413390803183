import * as types from "./types"

const initialState = {
    listOpd: {
        loadingGet: false,
        errorGet: false,
        messageGet: "",
        data: [],
        lastUpdated: "",
        author: ""
    },
    dataIkd: {
        loadingGet: false,
        errorGet: false,
        messageGet: "",
        data: [],
        lastUpdated: "",
        author: ""
    }
}

export default function ikdReducer(state = initialState, actions) {
    switch (actions.type) {
        case types.GET_LISTOPD_IKD_START:
            return {
                ...state,
                listOpd: {
                    ...state.listOpd,
                    loadingGet: true,
                    errorGet: false,
                    messageGet: 'waiting',
                },
            };
        case types.GET_LISTOPD_IKD_SUCCESS:
            return {
                ...state,
                listOpd: {
                    loadingGet: false,
                    errorGet: false,
                    messageGet: actions.payload.message,
                    data: actions.payload.data,
                    lastUpdated: actions.payload.timestamp,
                    author: actions.payload.author
                },
            };
        case types.GET_LISTOPD_IKD_FAILED:
            return {
                ...state,
                dataIkd: {
                    ...state.listOpd,
                    loadingGet: false,
                    errorGet: true,
                    messageGet: actions.payload.message,
                },
            };
        case types.GET_DATA_IKD_START:
            return {
                ...state,
                dataIkd: {
                    ...state.listOpd,
                    loadingGet: true,
                    errorGet: false,
                    messageGet: 'waiting',
                },
            };
        case types.GET_DATA_IKD_SUCCESS:
            return {
                ...state,
                dataIkd: {
                    loadingGet: false,
                    errorGet: false,
                    messageGet: actions.payload.message,
                    data: actions.payload.data,
                    lastUpdated: actions.payload.timestamp,
                    author: actions.payload.author
                },
            };
        case types.GET_DATA_IKD_FAILED:
            return {
                ...state,
                listOpd: {
                    ...state.listOpd,
                    loadingGet: false,
                    errorGet: true,
                    messageGet: actions.payload.message,
                },
            };
        default:
            return state;
    }
}