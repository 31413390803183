import { types } from './action';
const initialState = {
  loading: false,
  error: false,
  message: '',
  isLogin: false,
  token: null,
  biodata: {},
};

export default function authReducer(state = initialState, actions) {
  switch (actions.type) {
    case types.AUTH_LOADING:
      return {
        ...state,
        loading: true,
      };
    case types.AUTH_FAILED:
      return {
        ...state,
        error: true,
        loading: false,
        message: actions.payload,
      };
    case types.AUTH_SUCCESS:
      return {
        ...state,
        isLogin: true,
        biodata: actions.payload.data,
        token: actions.payload.token,
      };
    case types.AUTH_DESTROY:
      return {
        ...state,
        isLogin: false,
        biodata: {},
        token: '',
      };
    default:
      return state;
  }
}
