import React from 'react';
import Layout from '../../components/Layout';
import { numberFormatter, NumberDenganKoma } from '../../helper';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { getRealisasiOpdPbj } from '../../../redux/ducks/home/actions';
import { useCallback } from 'react';
import Moment from 'react-moment';
import BackButton from '../../components/BackButton';

const DetailPbj = (props) => {
  const [colorTr, setColorTr] = React.useState(null);
  const [filterData, setFilterData] = React.useState('');
  const [valueSelect, setValueSelect] = React.useState('');
  const [sortFill, setSortFill] = React.useState(null);
  const [popUpLogin, setPopUpLogin] = React.useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { yearconfig } = useParams()
  const type = searchParams.get('type');
  const typeName = searchParams.get('typeName');

  React.useEffect(() => {
    props.getDataOpd(type,yearconfig);
    setValueSelect(type);
  }, [type,yearconfig]);

  const searchByOpd = () => {
    if (filterData == '') {
      if (sortFill != null) {
        let data = [...props.dataOpd].sort((a, b) => {
          if (a[sortFill.key] < b[sortFill.key]) {
            return sortFill.direction === 'ascending' ? -1 : 1;
          }
          if (a[sortFill.key] > b[sortFill.key]) {
            return sortFill.direction === 'ascending' ? 1 : -1;
          }
        });

        return data;
      }
      return props.dataOpd;
    }
    if (filterData !== '') {
      let dataFilter = props.dataOpd.filter((items) =>
        items.namasatker.toLowerCase().includes(filterData.toLowerCase()),
      );
      if (sortFill != null) {
        dataFilter.sort((a, b) => {
          if (a[sortFill.key] < b[sortFill.key]) {
            return sortFill.direction === 'ascending' ? -1 : 1;
          }
          if (a[sortFill.key] > b[sortFill.key]) {
            return sortFill.direction === 'ascending' ? 1 : -1;
          }
        });
      }
      return dataFilter;
    }
  };

  const checkAnd = (title) => {
    return title.replace('dan', '&');
  };
  const checkAndReverse = (title) => {
    return title.replace('&', 'dan');
  };
  const breadCrumbsData = [
    {
      title: 'Home',
      isActive: true,
      goTo: '/tahun/'+yearconfig,
    },
    {
      title: `Data Realisasi ${checkAnd(typeName)}`,
      isActive: false,
      goTo: '',
    },
  ];

  const handleDynamicQueryString = (e) => {
    let index = e.nativeEvent.target.selectedIndex;
    setValueSelect(e.target.value);
    setSearchParams({ type: e.target.value, typeName: checkAndReverse(e.nativeEvent.target[index].text) });
  };

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortFill?.key == key && sortFill?.direction == 'ascending') {
      direction = 'descending';
    }
    setSortFill({ key, direction });
    console.log('sortfiglg', sortFill);
  };

  const _showPopUpLogin = (items) => {
    //if (props.isLogin) {
    return (
      <Link
        to={`/tahun/${yearconfig}/sipedal/detail-paket-sipedal-opd/${items.idsatker}/${items.namasatker}?type=${type}&typeName=${typeName}`}
        className="text-blue-500 underline text-left text-xs font-bold"
      >
        {items.namasatker}
      </Link>
    );
    // } else {
    //   return (
    //     <button onClick={() => setPopUpLogin(true)} className="text-blue-500 underline text-left text-xs font-bold">
    //       {items.namasatker}
    //     </button>
    //   );
    // }
  };
  let j1 = 0;
  let j2 = 0;
  let j3 = 0;
  let j4 = 0;
  let j5 = 0;
  let j6 = 0;
  let j7 = 0;
  let j8 = 0;
  let j9 = 0;
  let j10 = 0;
  let j11 = 0;
  let j12 = 0;
  return (
    <Layout
      loading={props.loading}
      breadCrumbsData={breadCrumbsData}
      popUpShow={popUpLogin}
      closePopUp={() => setPopUpLogin(false)}
    >
      <div className="flex flex-col">
        <div className="flex-grow bg-white rounded-xl p-5 relative">
          <h1 className="text-lg font-bold   text-center">Data Realisasi {checkAnd(typeName)}</h1>
          <p className="text-lg font-bold text-center">Tahun {yearconfig}</p>
          <h1 className="text-sm text-slate-600 mb-1 text-center">
            Sampai Dengan {props.loading ? '' : <Moment format="D MMMM YYYY">{props.timestamp}</Moment>}
          </h1>
          <hr className="mb-3" />
          {/* <div className="p-2 flex flex-col md:flex-row lg:flex-row bg-gray-200 shadow-md  mt-3 rounded-md">
            <p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 text-orange-500"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M18 10c0 3.866-3.582 7-8 7a8.841 8.841 0 01-4.083-.98L2 17l1.338-3.123C2.493 12.767 2 11.434 2 10c0-3.866 3.582-7 8-7s8 3.134 8 7zM7 9H5v2h2V9zm8 0h-2v2h2V9zM9 9h2v2H9V9z"
                  clip-rule="evenodd"
                />
              </svg>
            </p>
            <p className="w-2"></p>
            <p className="text-xs text-gary-700 font-bold ">
              Untuk menampilkan detail realisasi paket per opd silahkan klik nama opd. Data yang ditampilkan terakhir
              update <span className="font-bold text-red-500">{props.timestamp}</span>
            </p>
          </div> */}
          <div className="flex flex-col lg:flex-row xl:flex-row justify-between">
            <div className="py-2 w-full lg:w-[30%] xl:w-[30%]">
              <label for="table-search" className="sr-only">
                Search
              </label>
              <div className="relative mt-1">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg
                    className="w-5 h-5 text-gray-500"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
                <input
                  type="text"
                  onChange={(e) => setFilterData(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-800 text-sm font-bold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-1.5 placeholder:text-gray-900 "
                  placeholder="Cari berdasarkan nama OPD"
                />
              </div>
            </div>
            <div className="py-2 w-full lg:w-[30%] xl:w-[30%]">
              <label for="table-search" className="sr-only">
                Search
              </label>
              <div className="relative mt-1">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-5 h-5 text-gray-500"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
                    />
                  </svg>
                </div>
                <select
                  value={valueSelect}
                  className="bg-gray-50 border border-gray-300 text-gray-800 text-sm font-bold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-1.5 placeholder:text-gray-900"
                  onChange={handleDynamicQueryString}
                >
                  <option value="3">E-Purchasing</option>
                  <option value="1">Tendering</option>
                  <option value="2">Non-Tendering & Non E-Purchasing</option>
                  <option value="">Keseluruhan</option>
                </select>
              </div>
            </div>
          </div>
          <table className="w-full table-auto text-xs mb-16">
            <thead className="bg-primaryGreen sticky top-0">
              <tr>
                <th className="border border-slate-500 " rowSpan={3}>
                  No
                </th>
                <th className="border border-slate-500 " rowSpan={3}>
                  OPD
                </th>
                <th className="border border-slate-500 p-2" colSpan={12}>
                  PBJ
                </th>
              </tr>
              <tr>
                <th className="border border-slate-500 " colSpan={2}>
                  TOTAL
                </th>
                <th className="border border-slate-500 p-1" colSpan={2}>
                  BELUM PROSES
                </th>
                <th className="border border-slate-500 py-3" colSpan={2}>
                  SEDANG PROSES PEMILIHAN
                </th>
                <th className="border border-slate-500 p-1" colSpan={2}>
                  SELESAI PROSES PEMILIHAN
                </th>
                <th className="border border-slate-500 p-1" colSpan={2}>
                  KONTRAK
                </th>
                <th className="border border-slate-500 p-1" colSpan={2}>
                  SELESAI PELAKSANAAN
                </th>
              </tr>
              <tr>
                <th className="border border-slate-500 py-2 px-3">
                  <div className="flex justify-between">
                    Paket
                    {/* {sortFill?.direction === 'ascending' && sortFill?.key === 'rup_paket_jumlah' ? (
                      <svg
                        onClick={() => requestSort('rup_paket_jumlah')}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-3 h-3 text-gray-800 bg-gray-200 mt-1 rounded-sm "
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                      </svg>
                    ) : (
                      <svg
                        onClick={() => requestSort('rup_paket_jumlah')}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-3 h-3 text-gray-800 bg-gray-200 mt-1 rounded-sm"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                      </svg>
                    )} */}
                  </div>
                </th>
                <th className="border border-slate-500 p-1">
                  <div className="flex justify-around">
                    Pagu{' '}
                    {/* {sortFill?.direction === 'ascending' && sortFill?.key === 'rup_pagu_jumlah' ? (
                      <svg
                        onClick={() => requestSort('rup_pagu_jumlah')}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-3 h-3 text-gray-800 bg-gray-200 mt-1 rounded-sm"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                      </svg>
                    ) : (
                      <svg
                        onClick={() => requestSort('rup_pagu_jumlah')}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-3 h-3 text-gray-800 bg-gray-200 mt-1 rounded-sm"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                      </svg>
                    )} */}
                  </div>
                </th>
                <th className="border border-slate-500 py-2 px-3">
                  <div className="flex justify-around">
                    Paket{' '}
                    {/* {sortFill?.direction === 'ascending' && sortFill?.key === 'belum_proses_paket_jumlah' ? (
                      <svg
                        onClick={() => requestSort('belum_proses_paket_jumlah')}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-3 h-3 text-gray-800 bg-gray-200 mt-1 rounded-sm"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                      </svg>
                    ) : (
                      <svg
                        onClick={() => requestSort('belum_proses_paket_jumlah')}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-3 h-3 text-gray-800 bg-gray-200 mt-1 rounded-sm"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                      </svg>
                    )} */}
                  </div>
                </th>
                <th className="border border-slate-500 p-1">
                  <div className="flex justify-around">
                    Pagu{' '}
                    {/* {sortFill?.direction === 'ascending' && sortFill?.key === 'belum_proses_pagu_jumlah' ? (
                      <svg
                        onClick={() => requestSort('belum_proses_pagu_jumlah')}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-3 h-3 text-gray-800 bg-gray-200 mt-1 rounded-sm"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                      </svg>
                    ) : (
                      <svg
                        onClick={() => requestSort('belum_proses_pagu_jumlah')}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-3 h-3 text-gray-800 bg-gray-200 mt-1 rounded-sm"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                      </svg>
                    )} */}
                  </div>
                </th>
                <th className="border border-slate-500 py-2 px-3">
                  <div className="flex justify-around">
                    Paket{' '}
                    {/* {sortFill?.direction === 'ascending' && sortFill?.key === 'pemilihan_paket_jumlah' ? (
                      <svg
                        onClick={() => requestSort('pemilihan_paket_jumlah')}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-3 h-3 text-gray-800 bg-gray-200 mt-1 rounded-sm"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                      </svg>
                    ) : (
                      <svg
                        onClick={() => requestSort('pemilihan_paket_jumlah')}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-3 h-3 text-gray-800 bg-gray-200 mt-1 rounded-sm"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                      </svg>
                    )} */}
                  </div>
                </th>
                <th className="border border-slate-500 p-1">
                  <div className="flex justify-around">
                    HPS{' '}
                    {/* {sortFill?.direction === 'ascending' && sortFill?.key === 'pemilihan_hps_jumlah' ? (
                      <svg
                        onClick={() => requestSort('pemilihan_hps_jumlah')}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-3 h-3 text-gray-800 bg-gray-200 mt-1 rounded-sm"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                      </svg>
                    ) : (
                      <svg
                        onClick={() => requestSort('pemilihan_hps_jumlah')}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-3 h-3 text-gray-800 bg-gray-200 mt-1 rounded-sm"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                      </svg>
                    )} */}
                  </div>
                </th>
                <th className="border border-slate-500 py-2 px-3">
                  <div className="flex justify-around">
                    Paket{' '}
                    {/* {sortFill?.direction === 'ascending' && sortFill?.key === 'selesai_paket_jumlah' ? (
                      <svg
                        onClick={() => requestSort('selesai_paket_jumlah')}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-3 h-3 text-gray-800 bg-gray-200 mt-1 rounded-sm"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                      </svg>
                    ) : (
                      <svg
                        onClick={() => requestSort('selesai_paket_jumlah')}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-3 h-3 text-gray-800 bg-gray-200 mt-1 rounded-sm"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                      </svg>
                    )} */}
                  </div>
                </th>
                <th className="border border-slate-500 p-1">
                  <div className="flex justify-around">
                    Penawaran{' '}
                    {/* {sortFill?.direction === 'ascending' && sortFill?.key === 'selesai_penawaran_jumlah' ? (
                      <svg
                        onClick={() => requestSort('selesai_penawaran_jumlah')}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-3 h-3 text-gray-800 bg-gray-200 mt-1 rounded-sm"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                      </svg>
                    ) : (
                      <svg
                        onClick={() => requestSort('selesai_penawaran_jumlah')}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-3 h-3 text-gray-800 bg-gray-200 mt-1 rounded-sm"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                      </svg>
                    )} */}
                  </div>
                </th>
                <th className="border border-slate-500 py-2 px-3">
                  <div className="flex justify-around">
                    Paket{' '}
                    {/* {sortFill?.direction === 'ascending' && sortFill?.key === 'kontrak_paket_jumlah' ? (
                      <svg
                        onClick={() => requestSort('kontrak_paket_jumlah')}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-3 h-3 text-gray-800 bg-gray-200 mt-1 rounded-sm"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                      </svg>
                    ) : (
                      <svg
                        onClick={() => requestSort('kontrak_paket_jumlah')}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-3 h-3 text-gray-800 bg-gray-200 mt-1 rounded-sm"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                      </svg>
                    )} */}
                  </div>
                </th>
                <th className="border border-slate-500 p-1">
                  <div className="flex justify-around">
                    Nilai{' '}
                    {/* {sortFill?.direction === 'ascending' && sortFill?.key === 'kontrak_nilai_jumlah' ? (
                      <svg
                        onClick={() => requestSort('kontrak_nilai_jumlah')}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-3 h-3 text-gray-800 bg-gray-200 mt-1 rounded-sm"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                      </svg>
                    ) : (
                      <svg
                        onClick={() => requestSort('kontrak_nilai_jumlah')}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-3 h-3 text-gray-800 bg-gray-200 mt-1 rounded-sm"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                      </svg>
                    )} */}
                  </div>
                </th>
                <th className="border border-slate-500 py-2 px-3">
                  <div className="flex justify-around">
                    Paket{' '}
                    {/* {sortFill?.direction === 'ascending' && sortFill?.key === 'bast_paket_jumlah' ? (
                      <svg
                        onClick={() => requestSort('bast_paket_jumlah')}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-3 h-3 text-gray-800 bg-gray-200 mt-1 rounded-sm"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                      </svg>
                    ) : (
                      <svg
                        onClick={() => requestSort('bast_paket_jumlah')}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-3 h-3 text-gray-800 bg-gray-200 mt-1 rounded-sm"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                      </svg>
                    )} */}
                  </div>
                </th>
                <th className="border border-slate-500 p-1">
                  <div className="flex justify-around">
                    Kontrak{' '}
                    {/* {sortFill?.direction === 'ascending' && sortFill?.key === 'bast_nilai_jumlah' ? (
                      <svg
                        onClick={() => requestSort('bast_nilai_jumlah')}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-3 h-3 text-gray-800 bg-gray-200 mt-1 rounded-sm"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                      </svg>
                    ) : (
                      <svg
                        onClick={() => requestSort('bast_nilai_jumlah')}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-3 h-3 text-gray-800 bg-gray-200 mt-1 rounded-sm"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                      </svg>
                    )} */}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {searchByOpd().map((items, keys) => {
                j1 = j1 + items.rup_paket_jumlah
                j2 = j2 + items.rup_pagu_jumlah
                j3 = j3 + items.belum_proses_paket_jumlah
                j4 = j4 + items.belum_proses_pagu_jumlah
                j5 = j5 + items.pemilihan_paket_jumlah
                j6 = j6 + items.pemilihan_hps_jumlah
                j7 = j7 + items.selesai_paket_jumlah
                j8 = j8 + items.selesai_penawaran_jumlah
                j9 = j9 + items.kontrak_paket_jumlah
                j10 = j10 + items.kontrak_nilai_jumlah
                j11 = j11 + items.bast_paket_jumlah
                j12 = j12 + items.bast_nilai_jumlah
                return (
                  <tr
                    key={keys}
                    onClick={() => setColorTr(keys)}
                    className={colorTr === keys ? 'bg-green-200' : 'cursor-pointer'}
                  >
                    <td className="border border-slate-500 text-right p-3">{keys + 1}</td>
                    <td className="border border-slate-500 py-3 px-2 w-[40%]">{_showPopUpLogin(items)}</td>
                    <td className="border border-slate-500 text-right font-bold p-2 ">{items.rup_paket_jumlah}</td>
                    <td className="border border-slate-500 text-right font-bold p-2">
                      {' '}
                      {numberFormatter(items.rup_pagu_jumlah)}
                    </td>
                    {/* BELUM PROSES */}
                    <td className="border border-slate-500 text-right font-bold p-2 w-[9%]">
                      {numberFormatter(items.belum_proses_paket_jumlah)}
                    </td>
                    <td className="border border-slate-500 text-right font-bold p-2">
                      {numberFormatter(items.belum_proses_pagu_jumlah)}
                    </td>
                    {/* AKHIR BELUM PROSES */}
                    {/* SEDANG PROSES PEMILIHAN */}
                    <td className="border border-slate-500 text-right font-bold p-2 w-[9%]">
                      {numberFormatter(items.pemilihan_paket_jumlah)}
                    </td>
                    <td className="border border-slate-500 text-right font-bold p-2">
                      {numberFormatter(items.pemilihan_hps_jumlah)}
                    </td>
                    {/* AKHIR SEDANG PROSES PEMILIHAN */}
                    {/* SELESAI PROSES PEMILIHAN */}
                    <td className="border border-slate-500 text-right font-bold p-2 w-[9%]">
                      {numberFormatter(items.selesai_paket_jumlah)}
                    </td>
                    <td className="border border-slate-500 text-right font-bold p-2">
                      {numberFormatter(items.selesai_penawaran_jumlah)}
                    </td>
                    {/* AKHIR SELESAI PROSES PEMILIHAN */}
                    {/* KONTRAK */}
                    <td className="border border-slate-500 text-right font-bold p-2 w-[9%]">
                      {numberFormatter(items.kontrak_paket_jumlah)}
                    </td>
                    <td className="border border-slate-500 text-right font-bold p-2">
                      {NumberDenganKoma(items.kontrak_nilai_jumlah)}
                    </td>
                    {/*AKHIR KONTRAK */}
                    {/* SELESAI PELAKSANAAN */}
                    <td className="border border-slate-500 text-right font-bold p-2 w-[9%]">{items.bast_paket_jumlah}</td>
                    <td className="border border-slate-500 text-right font-bold p-2">
                      {numberFormatter(items.bast_nilai_jumlah)}
                    </td>
                    {/* AKHIR SELESAI PELKASANAN */}
                  </tr>
                );
              })}
              <tr>
                <td colspan="2" className="border border-slate-500 text-center font-bold p-2">Jumlah</td>
                <td className="border border-slate-500 text-right font-bold p-2">{numberFormatter(j1)}</td>
                <td className="border border-slate-500 text-right font-bold p-2">{numberFormatter(j2)}</td>
                <td className="border border-slate-500 text-right font-bold p-2">{numberFormatter(j3)}</td>
                <td className="border border-slate-500 text-right font-bold p-2">{numberFormatter(j4)}</td>
                <td className="border border-slate-500 text-right font-bold p-2">{numberFormatter(j5)}</td>
                <td className="border border-slate-500 text-right font-bold p-2">{numberFormatter(j6)}</td>
                <td className="border border-slate-500 text-right font-bold p-2">{numberFormatter(j7)}</td>
                <td className="border border-slate-500 text-right font-bold p-2">{numberFormatter(j8)}</td>
                <td className="border border-slate-500 text-right font-bold p-2">{numberFormatter(j9)}</td>
                <td className="border border-slate-500 text-right font-bold p-2">{numberFormatter(j10)}</td>
                <td className="border border-slate-500 text-right font-bold p-2">{numberFormatter(j11)}</td>
                <td className="border border-slate-500 text-right font-bold p-2">{numberFormatter(j12)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <BackButton backLink={`/tahun/${yearconfig}`} />
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  dataOpd: state.homeState.pbjDetailOpd.data,
  loading: state.homeState.pbjDetailOpd.loadingGet,
  error: state.homeState.pbjDetailOpd.errorGet,
  timestamp: state.homeState.pbjDetailOpd.last_update,
  isLogin: state.authState.isLogin,
});

const mapDispatchToProps = {
  getDataOpd: getRealisasiOpdPbj,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(DetailPbj);
