import React, { useEffect, memo, useState } from 'react'
import { connect } from 'react-redux';
import { compose } from 'redux';
import Layout from '../../../components/Layout';
import { getListOpdIkd_act } from "../../../../redux/ducks/ikd/actions"
import { NavLink, useParams } from 'react-router-dom';
import BackButton from '../../../components/BackButton';

const ListOpd = (props) => {
    const { yearconfig } = useParams()
    useEffect(() => {
      props.getListOpdIkd()
    },[])
    const breadCrumbsData = [
        {
          title: 'Home',
          isActive: true,
          goTo: '/tahun/'+yearconfig,
        },
        {
          title: `Indikator Kinerja Daerah`,
          isActive: false,
          goTo: '',
        },
    ];
    const [searchFilter, setSearchFilter] = useState("")
    const filteredBySearch = () => {
        if (searchFilter === '') {
          return props.listOpd.data;
        } else return props.listOpd.data.filter((items) => items.instansi.toLowerCase().includes(searchFilter.toLowerCase()));
    }
  return (
    <Layout
      loading={props.listOpd.loadingGet}
      breadCrumbsData={breadCrumbsData}
    >
      <div
        className="bg-white rounded-lg drop-shadow-2xl py-5 px-2 w-full"
      >
          <div className="flex-grow bg-white rounded-xl py-2 relative">
            <h1 className="text-lg font-bold text-center">Indikator Kinerja Daerah</h1>
            <h1 className="mb-1 text-sm text-gray-400 text-center">
              pilih OPD di bawah untuk melihat indikator kinerja daerah
            </h1>
            <hr className="mb-3" />
          </div>
          <div className="flex flex-col lg:flex-row xl:flex-row justify-center mb-3">
              <div className="py-2 w-full lg:w-[30%] xl:w-[30%]">
                <label for="table-search" className="sr-only">
                  Search
                </label>
                <div className="relative mt-1">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg
                      className="w-5 h-5 text-gray-500"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </div>
                  <input
                    type="text"
                    onChange={(e) => setSearchFilter(e.target.value)}
                    className="bg-gray-50 border border-gray-300 text-gray-800 text-sm font-bold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-1.5 placeholder:text-gray-900 "
                    placeholder="Cari berdasarkan nama OPD"
                  />
                </div>
              </div>
          </div>
          <div className="w-full grid xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-1">
              {
                  filteredBySearch().map((val,keys) => {
                      return (
                            <div>
                              <NavLink to={`/tahun/${yearconfig}/ikd/data-ikd-opd/${val.id}/${val.instansi}`} 
                                className="cursor-pointer flex flex-row gap-3 p-2 group rounded-xl group hover:bg-white hover:text-green-500 bg-green-500 text-white hover:border-green-500 border-white border-2 h-16 overflow-hidden sm:items-center">
                                  <div className="flex items-center justify-center p-1">
                                      <span 
                                          className="rounded-full p-1.5 group-hover:bg-green-500 group-hover:text-white bg-white text-green-500">
                                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0012 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75z" />
                                          </svg>

                                      </span>
                                  </div>
                                  <p className="text-[1em] leading-none">
                                    {val.instansi}
                                  </p>
                              </NavLink>
                            </div>
                          )
                      })
              }
          </div>
          {/* <div className="w-full overflow-x-auto flex justify-center">
              <table className="md:w-1/2 w-full table-auto text-sm mb-16">
                <thead className="bg-primaryGreen sticky top-0">
                  <tr>
                    <th className="border border-slate-500  w-[5%]">
                      No
                    </th>
                    <th className="border border-slate-500 ">
                      OPD
                    </th>
                    
                  </tr>
                </thead>
                <tbody>
                {
                    filteredBySearch().map((val,keys) => {
                      
                      return (
                        <tr
                          key={keys}
                        >
                            <td className="border border-slate-500 p-2 text-center">{keys+1}</td>
                            <td className="border border-slate-500 p-2">
                                <Link
                                  to={`/ikd/data-ikd-opd/${val.id}/${val.instansi}`}
                                  className="text-blue-500 underline text-left text-xs font-bold"
                                >
                                    {val.instansi}
                                </Link>
                            </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
          </div> */}
      </div>
      <BackButton backLink={'/tahun/'+yearconfig} />
    </Layout>
  )
}

const mapStateToProps = (state) => ({
  listOpd: state.ikdState.listOpd
})

const mapDispatchToProps = {
  getListOpdIkd: getListOpdIkd_act
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect,memo)(ListOpd)