const GET_PROGRAM_UNGGULAN_START = "GET_PROGRAM_UNGGULAN_START"
const GET_PROGRAM_UNGGULAN_SUCCESS = "GET_PROGRAM_UNGGULAN_SUCCESS"
const GET_PROGRAM_UNGGULAN_FAILED = "GET_PROGRAM_UNGGULAN_FAILED"

const GET_DATA_PROGUL_START = "GET_DATA_PROGUL_START"
const GET_DATA_PROGUL_SUCCESS = "GET_DATA_PROGUL_SUCCESS"
const GET_DATA_PROGUL_FAILED = "GET_DATA_PROGUL_FAILED"

export {
    GET_PROGRAM_UNGGULAN_START,
    GET_PROGRAM_UNGGULAN_SUCCESS,
    GET_PROGRAM_UNGGULAN_FAILED,

    GET_DATA_PROGUL_START,
    GET_DATA_PROGUL_SUCCESS,
    GET_DATA_PROGUL_FAILED
}