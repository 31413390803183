import * as types from './types'

const initialState = {
    visitors: {
        loadingGet: false,
        errorGet: false,
        messageGet: "",
        data: {
            today: 0,
            this_week: 0,
            this_month: 0,
            this_year: 0,
            total: 0,
            currently_online: 0
        },
        lastUpdated: ""
    }
}

export default function visitorsReducer(state = initialState, actions){
    switch(actions.type){
        case types.GET_STATS_VISITORS_START:
            return{
                ...state,
                visitors:{
                    ...state.visitors,
                    loadingGet: true,
                    errorGet: false,
                    messageGet: 'loading...'
                }
            }
        
        case types.GET_STATS_VISITORS_SUCCESS:
            return {
                ...state,
                visitors:{
                    ...state.visitors,
                    loadingGet: false,
                    errorGet: false,
                    messageGet: actions.payload.message,
                    data: actions.payload.data,
                }
            }

        case types.GET_STATS_VISITORS_FAILED:
            return {
                ...state,
                visitors:{
                    ...state.visitors,
                    loadingGet: false,
                    errorGet: true,
                    messageGet: actions.payload.message,
                }
            }

        default: 
            return state
    }
}