import React, { useEffect, memo, useState } from 'react'
import { connect } from 'react-redux';
import { compose } from 'redux';
import Layout from '../../../components/Layout';
import { getProgramUnggulan_act } from "../../../../redux/ducks/progul/actions"
import { Disclosure } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/20/solid'
import { NavLink, useParams } from 'react-router-dom';
import BackButton from '../../../components/BackButton';

const TemaProgramUnggulan = (props) => {
    const { yearconfig } = useParams()
    useEffect(() => {
        props.getProgramUnggulan()
    },[])
    const breadCrumbsData = [
        {
          title: 'Home',
          isActive: true,
          goTo: '/tahun/'+yearconfig,
        },
        {
          title: `Program Unggulan`,
          isActive: false,
          goTo: '',
        },
    ];
    const [proguls, setProguls] = useState([])
    useEffect(() => {
      if(props.programUnggulan.data.length > 0){
        let arra = []
        props.programUnggulan.data.map((items) => {
          items.proguls.map((val) =>{
            arra.push(val)
          })
        })
        setProguls(arra)
      }
      console.log(proguls);
    },[props.programUnggulan])
    return (
        <Layout
            loading={props.programUnggulan.loadingGet}
            breadCrumbsData={breadCrumbsData}
        >
          <div
              className="bg-white rounded-lg drop-shadow-2xl py-5 px-2 w-full"
          >
              <div className="flex-grow bg-white rounded-xl py-2 relative">
                  <h1 className="text-lg font-bold text-center">Program Unggulan</h1>
                  {/* <h1 className="mb-1 text-sm text-gray-400 text-center">
                      pilih Tema Program Unggulan di bawah untuk melihat target dan capaian Program Unggulan
                  </h1> */}
                  <hr className="mb-5" />
              </div>
              <div className="w-full px-4">
                  <div className="flex flex-col md:gap-8 gap-5 items-center mx-auto w-full rounded-2xl bg-white p-2">
                        {/* <div className="grid xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 justify-center items-stretch gap-3 max-w-7xl">
                      {
                          proguls.map((val) => {
                            return(
                              <NavLink 
                                to={`/progul/data/${val.id}/${encodeURIComponent(val.progul)}`} 
                                alt={val.alias}
                                className="rounded-xl p-4 font-extrabold cursor-pointer overflow-hidden hover:scale-[1.05] hover:transform">
                                <div className="w-full flex items-center justify-center overflow-hidden">
                                  <img src={`/img/progul_new/progul_${val.id}.png`} className='object-contain' alt="" srcset="" />
                                </div>
                              </NavLink>
                            )
                          })
                      }
                        </div> */}
                      
                      {
                        props.programUnggulan.data.map((items) => {
                            return (
                              <div className='max-w-6xl w-full'>
                                <h2 className="md:text-lg sm:text-base text-sm font-bold mb-3 border-b-2">{items.tema}</h2>
                                <div className="grid xl:grid-cols-8 lg:grid-cols-6 md:grid-cols-4 grid-cols-2 justify-center items-stretch md:gap-2 gap-1">
                                  {
                                    items.proguls.map((val) => {
                                      return(
                                        <NavLink 
                                          to={`/tahun/${yearconfig}/progul/data/${val.id}/${encodeURIComponent(val.progul)}`} 
                                          className="rounded-xl p-1 font-extrabold cursor-pointer overflow-hidden hover:scale-[1.2] hover:transform">
                                          <div className="w-full flex items-center justify-center overflow-hidden">
                                            <img src={`/img/progul_new/progul_${val.id}.png`} className='object-contain' alt={val.alias} srcset="" />
                                          </div>
                                        </NavLink>
                                      )
                                    })
                                  }
                                </div>
                              </div>
                            )
                        })
                      }
                      
                  </div>
              </div>
          </div>
          <BackButton backLink={'/tahun/'+yearconfig} />
        </Layout>
    )
}

const mapStateToProps = (state) => ({
    programUnggulan: state.progulState.programUnggulan
})

const mapDispatchToProps = {
    getProgramUnggulan: getProgramUnggulan_act
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect, memo)(TemaProgramUnggulan)