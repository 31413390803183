import * as types from './types'

const getCapaianKinerjaGubernur_act = (tahun = "") => async (dispatch, getState, Api) => {
    dispatch({
        type: types.GET_CPK_GUBERNUR_START
    })
    const response = await Api.getCapaianPKGubernur(tahun)
    if (response.status !== "failed") {
        console.log(response.data);
        dispatch({
            type: types.GET_CPK_GUBERNUR_SUCCESS,
            payload: {
                data: response.data.message,
                message: response.data.label
            }
        })
    } else {
        dispatch({
            type: types.GET_CPK_GUBERNUR_FAILED,
            payload: {
                message: response.error
            }
        })
    }
}

export {
    getCapaianKinerjaGubernur_act
}

