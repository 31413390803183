import React from 'react'
import Layout from '../../components/Layout'
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import BackButton from '../../components/BackButton';
import { numberFormatter } from '../../helper';
import { get_grafikDetailPendapatanPerBulan_act,get_grafikDataDetailPendapatanPerBulan_act } from '../../../redux/ducks/home/actions'

const DetailPendapatanPerbulan = (props) => {
    const d = new Date();
    let e = d.getMonth();
    const { yearconfig } = useParams();
    const [valueSelectBulan, setValueSelectBulan] = React.useState(e)
    const changeMonth = (e) => {
        setValueSelectBulan(e.target.value)
    }
    const breadCrumbsData = [
        {
            title: 'Home',
            isActive: true,
            goTo: '/tahun/'+yearconfig,
        },
        {
            title: 'Detail Pendapatan',
            isActive: true,
            goTo: `/tahun/${yearconfig}/bappenda/detail-pendapatan`,
        },
        {
            title: `Detail Pendapatan per Bulan`,
            isActive: false,
            goTo: '',
        },
    ];
    const months= [
        "Januari",
        "Febuari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
    ]
    React.useEffect(() => {
        
        props.get_grafikDataDetailPendapatanPerBulan_act(yearconfig, (parseInt(valueSelectBulan)+1))
    },[yearconfig, valueSelectBulan])
    return (
        <Layout breadCrumbsData={breadCrumbsData}>
            <div className="bg-white rounded-xl p-5 relative">
                <h1 className="text-lg font-bold text-center">TABEL DETAIL PENDAPATAN PER BULAN</h1>
                <h1 className="text-md font-bold mb-3  text-center">Tahun {yearconfig}</h1>
                <div className="w-full relative overflow-x-scroll flex flex-col justify-center items-center">
                    <div className="w-full flex justify-end mb-3">
                        <label for="table-search" className="sr-only">
                            Bulan
                        </label>
                        <div className="relative mt-1 lg:w-1/4 md:w-1/3 w-full">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-5 h-5 text-gray-500"
                                >
                                <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                    d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
                                />
                                </svg>
                            </div>
                            <select
                                className="bg-gray-50 border border-gray-300 text-gray-800 text-sm font-bold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-1.5 placeholder:text-gray-900"
                                value={valueSelectBulan}
                                onChange={changeMonth}
                            >
                                {months.map((value, index) => (
                                <option value={index}>{value}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <table className="w-full max-w-7xl text-xs">
                        <thead className="bg-primaryGreen sticky top-0">
                            <tr>
                                {/* <th className="border border-slate-500 p-2 w-[1%]" rowSpan={2}>
                                    No
                                </th> */}
                                <th className="border border-slate-500 w-[10%]" rowSpan={2}>
                                    Kode
                                </th>
                                <th className="border border-slate-500 w-[40%]" rowSpan={2}>
                                    Jenis Pendapatan
                                </th>
                                {
                                    months.map((item, index) => (
                                        index == valueSelectBulan ?
                                        <th className="border border-slate-500" colSpan={3} >{item}</th>
                                        : null
                                    ))
                                }
                            </tr>
                            <tr>
                                <th className="border border-slate-500" >
                                    <span className='hidden lg:block'>Target</span>
                                    <span className='block lg:hidden'>T</span>
                                </th>
                                <th className="border border-slate-500" >
                                    <span className='hidden lg:block'>Realisasi</span>
                                    <span className='block lg:hidden'>R</span>
                                </th>
                                <th className="border border-slate-500" >
                                    <span className='hidden lg:block'>Persentase</span>
                                    <span className='block lg:hidden'>%</span>
                                </th>
                            </tr>
                            
                        </thead>
                        <tbody>
                            {
                                props.detailPendaptanPerBulan.loadingGet ?
                                <tr>
                                    <td colSpan="100%" className='text-center border border-slate-500 py-3 bg-slate-200'>Loading...</td>
                                </tr>
                                :
                                props.detailPendaptanPerBulan.data.map((items, key) => (
                                    <tr key={key} className={items.groupkey == 1 ? "bg-orange-100 text-sm font-bold" : items.groupkey == 2 ? "bg-green-500 font-bold" : items.groupkey == 3 ? "bg-green-300 font-bold" : "bg-white"}>
                                        <td className={`border border-slate-500 text-left`}>
                                            {items.kode}
                                        </td>
                                        <td className={`border border-slate-500 text-left`}>
                                            {items.uraian}
                                        </td>
                                        <td className={`border border-slate-500 text-right`}>
                                            {numberFormatter(items.target)}
                                        </td>
                                        <td className={`border border-slate-500 text-right`}>
                                            {numberFormatter(items.realisasi)}
                                        </td>
                                        <td className={`border border-slate-500 text-right`}>
                                            {numberFormatter(items.persentase)} %
                                        </td>
                                                
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <BackButton backLink={`/tahun/${yearconfig}/bappenda/detail-pendapatan`} />
        </Layout>
    )
}

const mapDispatchToProps = {
    get_grafikDetailPendapatanPerBulan_act,
    get_grafikDataDetailPendapatanPerBulan_act
}

const mapStateToProps = (state) => {
    return {
        detailPendaptanPerBulan: state.homeState.detailPendapatanPerBulan
    }
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default withConnect(DetailPendapatanPerbulan)