const GET_REALISASI_BELANJA_OPD_START = 'GET_REALISASI_BELANJA_OPD_START';
const GET_REALISASI_BELANJA_OPD_SUCCESS = 'GET_REALISASI_BELANJA_OPD_SUCCESS';
const GET_REALISASI_BELANJA_OPD_FAILED = 'GET_REALISASI_BELANJA_OPD_FAILED';

const GET_PENDAPATAN_DAERAH_START = 'GET_PENDAPATAN_DAERAH_START';
const GET_PENDAPATAN_DAERAH_SUCCESS = 'GET_PENDAPATAN_DAERAH_SUCCESS';
const GET_PENDAPATAN_DAERAH_FAILED = 'GET_PENDAPATAN_DAERAH_FAILED';

const GET_ALL_PENDAPATAN_START = 'GET_ALL_PENDAPATAN_START';
const GET_ALL_PENDAPATAN_SUCCESS = 'GET_ALL_PENDAPATAN_SUCCESS';
const GET_ALL_PENDAPATAN_FAILED = 'GET_ALL_PENDAPATAN_FAILED';

const GET_REALISASI_BELANJA_PER_OPD_START = 'GET_REALISASI_BELANJA_PER_OPD_START';
const GET_REALISASI_BELANJA_PER_OPD_SUCCESS = 'GET_REALISASI_BELANJA_PER_OPD_SUCCESS';
const GET_REALISASI_BELANJA_PER_OPD_FAILED = 'GET_REALISASI_BELANJA_PER_OPD_FAILED';

const GET_REALISASI_KEGIATAN_PER_OPD_START = 'GET_REALISASI_KEGIATAN_PER_OPD_START';
const GET_REALISASI_KEGIATAN_PER_OPD_SUCCESS = 'GET_REALISASI_KEGIATAN_PER_OPD_SUCCESS';
const GET_REALISASI_KEGIATAN_PER_OPD_FAILED = 'GET_REALISASI_KEGIATAN_PER_OPD_FAILED';

const GET_REALISASI_PBJ_START = 'GET_REALISASI_PBJ_START';
const GET_REALISASI_PBJ_SUCCESS = 'GET_REALISASI_PBJ_SUCCESS';
const GET_REALISASI_PBJ_FAILED = 'GET_REALISASI_PBJ_FAILED';

const GET_REALISASI_OPD_PBJ_START = 'GET_REALISASI_OPD_PBJ_START';
const GET_REALISASI_OPD_PBJ_SUCCESS = 'GET_REALISASI_OPD_PBJ_SUCCESS';
const GET_REALISASI_OPD_PBJ_FAILED = 'GET_REALISASI_OPD_PBJ_FAILED';

const GET_PAKET_OPD_PBJ_START = 'GET_PAKET_OPD_PBJ_START';
const GET_PAKET_OPD_PBJ_SUCCESS = 'GET_PAKET_OPD_PBJ_SUCCESS';
const GET_PAKET_OPD_PBJ_FAILED = 'GET_PAKET_OPD_PBJ_FAILED';

const GET_GRAFIK_SIMBANGDA_START = 'GET_GRAFIK_SIMBANGDA_START';
const GET_GRAFIK_SIMBANGDA_SUCCESS = 'GET_GRAFIK_SIMBANGDA_SUCCESS';
const GET_GRAFIK_SIMBANGDA_FAILED = 'GET_GRAFIK_SIMBANGDA_FAILED';

const GET_GRAFIK_PENDAPATAN_START = 'GET_GRAFIK_PENDAPATAN_START';
const GET_GRAFIK_PENDAPATAN_SUCCESS = 'GET_GRAFIK_PENDAPATAN_SUCCESS';
const GET_GRAFIK_PENDAPATAN_FAILED = 'GET_GRAFIK_PENDAPATAN_FAILED';

const GET_DATA_HIBAH_START = 'GET_DATA_HIBAH_START'
const GET_DATA_HIBAH_SUCCESS = 'GET_DATA_HIBAH_SUCCESS'
const GET_DATA_HIBAH_FAILED = 'GET_DATA_HIBAH_FAILED'

const GET_OPD_PEMBERI_HIBAH_START = 'GET_OPD_PEMBERI_HIBAH_START'
const GET_OPD_PEMBERI_HIBAH_SUCCESS = 'GET_OPD_PEMBERI_HIBAH_SUCCESS'
const GET_OPD_PEMBERI_HIBAH_FAILED = 'GET_OPD_PEMBERI_HIBAH_FAILED'

const GET_DETAIL_HIBAH_OPD_START = 'GET_DETAIL_HIBAH_OPD_START'
const GET_DETAIL_HIBAH_OPD_SUCCESS = 'GET_DETAIL_HIBAH_OPD_SUCCESS'
const GET_DETAIL_HIBAH_OPD_FAILED = 'GET_DETAIL_HIBAH_OPD_FAILED'

const GET_GRAFIK_PENDAPATAN_PERBULAN_START = 'GET_GRAFIK_PENDAPATAN_PERBULAN_START'
const GET_GRAFIK_PENDAPATAN_PERBULAN_SUCCESS = 'GET_GRAFIK_PENDAPATAN_PERBULAN_SUCCESS'
const GET_GRAFIK_PENDAPATAN_PERBULAN_FAILED = 'GET_GRAFIK_PENDAPATAN_PERBULAN_FAILED'

const GET_GRAFIK_DETAILPENDAPATAN_PERBULAN_START = 'GET_GRAFIK_DETAILPENDAPATAN_PERBULAN_START'
const GET_GRAFIK_DETAILPENDAPATAN_PERBULAN_SUCCESS = 'GET_GRAFIK_DETAILPENDAPATAN_PERBULAN_SUCCESS'
const GET_GRAFIK_DETAILPENDAPATAN_PERBULAN_FAILED = 'GET_GRAFIK_DETAILPENDAPATAN_PERBULAN_FAILED'

export {
  GET_REALISASI_BELANJA_OPD_START,
  GET_REALISASI_BELANJA_OPD_FAILED,
  GET_REALISASI_BELANJA_OPD_SUCCESS,
  
  GET_PENDAPATAN_DAERAH_START,
  GET_PENDAPATAN_DAERAH_SUCCESS,
  GET_PENDAPATAN_DAERAH_FAILED,

  GET_ALL_PENDAPATAN_START,
  GET_ALL_PENDAPATAN_SUCCESS,
  GET_ALL_PENDAPATAN_FAILED,

  GET_REALISASI_BELANJA_PER_OPD_FAILED,
  GET_REALISASI_BELANJA_PER_OPD_START,
  GET_REALISASI_BELANJA_PER_OPD_SUCCESS,

  GET_REALISASI_PBJ_START,
  GET_REALISASI_PBJ_FAILED,
  GET_REALISASI_PBJ_SUCCESS,

  GET_REALISASI_OPD_PBJ_START,
  GET_REALISASI_OPD_PBJ_SUCCESS,
  GET_REALISASI_OPD_PBJ_FAILED,

  GET_PAKET_OPD_PBJ_START,
  GET_PAKET_OPD_PBJ_SUCCESS,
  GET_PAKET_OPD_PBJ_FAILED,

  GET_REALISASI_KEGIATAN_PER_OPD_START,
  GET_REALISASI_KEGIATAN_PER_OPD_SUCCESS,
  GET_REALISASI_KEGIATAN_PER_OPD_FAILED,

  GET_GRAFIK_SIMBANGDA_START,
  GET_GRAFIK_SIMBANGDA_SUCCESS,
  GET_GRAFIK_SIMBANGDA_FAILED,

  GET_GRAFIK_PENDAPATAN_START,
  GET_GRAFIK_PENDAPATAN_FAILED,
  GET_GRAFIK_PENDAPATAN_SUCCESS,

  GET_DATA_HIBAH_START,
  GET_DATA_HIBAH_SUCCESS,
  GET_DATA_HIBAH_FAILED,

  GET_OPD_PEMBERI_HIBAH_START,
  GET_OPD_PEMBERI_HIBAH_SUCCESS,
  GET_OPD_PEMBERI_HIBAH_FAILED,

  GET_DETAIL_HIBAH_OPD_START,
  GET_DETAIL_HIBAH_OPD_SUCCESS,
  GET_DETAIL_HIBAH_OPD_FAILED,

  GET_GRAFIK_PENDAPATAN_PERBULAN_START,
  GET_GRAFIK_PENDAPATAN_PERBULAN_SUCCESS,
  GET_GRAFIK_PENDAPATAN_PERBULAN_FAILED,

  GET_GRAFIK_DETAILPENDAPATAN_PERBULAN_START,
  GET_GRAFIK_DETAILPENDAPATAN_PERBULAN_SUCCESS,
  GET_GRAFIK_DETAILPENDAPATAN_PERBULAN_FAILED
};
