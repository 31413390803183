import * as types from './types'


const getVisitors_act = () => async (dispatch, getState, Api) => {
    dispatch({ type: types.GET_STATS_VISITORS_START })

    const response = await Api.getStatsVisitors()
    if (response.status !== "failed") {
        dispatch({
            type: types.GET_STATS_VISITORS_SUCCESS,
            payload: {
                data: response.data.data,
                message: response.data.message,
            },
        });
    } else {
        dispatch({
            type: types.GET_STATS_VISITORS_FAILED,
            payload: {
                message: response.error,
            },
        });
    }
}

export {
    getVisitors_act
}