const GET_LISTOPD_IKD_START = "GET_LISTOPD_IKD_START"
const GET_LISTOPD_IKD_SUCCESS = "GET_LISTOPD_IKD_SUCCESS"
const GET_LISTOPD_IKD_FAILED = "GET_LISTOPD_IKD_FAILED"

const GET_DATA_IKD_START = "GET_DATA_IKD_START"
const GET_DATA_IKD_SUCCESS = "GET_DATA_IKD_SUCCESS"
const GET_DATA_IKD_FAILED = "GET_DATA_IKD_FAILED"

export {
     GET_LISTOPD_IKD_START,
     GET_LISTOPD_IKD_SUCCESS,
     GET_LISTOPD_IKD_FAILED,

     GET_DATA_IKD_START,
     GET_DATA_IKD_SUCCESS,
     GET_DATA_IKD_FAILED
}