const GET_LISTOPD_PK_START = "GET_LISTOPD_PK_START"
const GET_LISTOPD_PK_SUCCESS = "GET_LISTOPD_PK_SUCCESS"
const GET_LISTOPD_PK_FAILED = "GET_LISTOPD_PK_FAILED"

const GET_CAPAIAN_PK_START = "GET_CAPAIAN_PK_START"
const GET_CAPAIAN_PK_SUCCESS = "GET_CAPAIAN_PK_SUCCESS"
const GET_CAPAIAN_PK_FAILED = "GET_CAPAIAN_PK_FAILED"

export {
    GET_LISTOPD_PK_START,
    GET_LISTOPD_PK_SUCCESS,
    GET_LISTOPD_PK_FAILED,

    GET_CAPAIAN_PK_START,
    GET_CAPAIAN_PK_SUCCESS,
    GET_CAPAIAN_PK_FAILED
}