const GET_LAYANAN_IKP_START = "GET_LAYANAN_IKP_START"
const GET_LAYANAN_IKP_SUCCESS = "GET_LAYANAN_IKP_SUCCESS"
const GET_LAYANAN_IKP_FAILED = "GET_LAYANAN_IKP_FAILED"

const GET_DATA_IKP_START = "GET_DATA_IKP_START"
const GET_DATA_IKP_SUCCESS = "GET_DATA_IKP_SUCCESS"
const GET_DATA_IKP_FAILED = "GET_DATA_IKP_FAILED"

export {
    GET_LAYANAN_IKP_START,
    GET_LAYANAN_IKP_SUCCESS,
    GET_LAYANAN_IKP_FAILED,
    GET_DATA_IKP_START,
    GET_DATA_IKP_SUCCESS,
    GET_DATA_IKP_FAILED
}