import React from 'react';

function withRefillAuth(WrappedComponents) {
  const HOC = (props) => {
    props.refillAuth();
    return <WrappedComponents {...props} />;
  };

  return HOC;
}

export default withRefillAuth;
