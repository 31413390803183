import React, { useEffect, memo, useState } from 'react'
import { connect } from 'react-redux';
import { compose } from 'redux';
import Layout from '../../../components/Layout';
import { useParams } from 'react-router-dom';
import { getCapaianPerjanjianKinerja_act } from '../../../../redux/ducks/cpk/actions'
import BackButton from '../../../components/BackButton';


const triwulan = ["tw1", "tw2", "tw3", "tw4"]
const DataCapaian_PK = (props) => {
    const { opdId, opdName, yearconfig } = useParams();
    const dataBreadcrumbs = [
        {
          title: 'Home',
          isActive: true,
          goTo: '/tahun/'+yearconfig,
        },
        {
          title: `Capaian Perjanjian Kinerja Esselon II`,
          isActive: true,
          goTo: `/tahun/${yearconfig}/cpkess/listopd`,
        },
    ]
    const [breadCrumbsData, setBreadCrumbsData] = useState();
    useEffect(() => {
        setBreadCrumbsData([...dataBreadcrumbs, {
            title: opdName,
            isActive: false,
            goTo: ''
        }])
    },[])

    useEffect(() => {
        props.getCapaianPerjanjianKinerja(opdId, yearconfig)
    },[opdId, yearconfig])
    return (
        <Layout
            loading={false}
            breadCrumbsData={breadCrumbsData}
        >
            <div
                className="bg-white rounded-lg drop-shadow-2xl py-5 px-2 w-full"
            >
                <div className="flex-grow bg-white rounded-xl py-2 relative">
                    <h1 className="text-md text-center">Capaian Perjanjian Kinerja Esselon II</h1>
                    <h1 className="mb-1 text-2xl font-bold text-center">
                    {opdName}
                    </h1>
                    <h1 className="mb-1 text-md font-bold text-center">
                    Tahun {yearconfig}
                    </h1>
                    <hr className="mb-3" />
                </div>
                <div className="w-full sm:overflow-visible overflow-x-auto flex flex-col justify-center">
                    <table className="w-full table-auto text-xs mb-4">
                        <thead className="bg-primaryGreen sticky top-0">
                            <tr>
                                <th className="border border-slate-500  w-[3%]" rowSpan={3}>
                                    No
                                </th>
                                <th className="border border-slate-500 md:w-1/4" rowSpan={3}>
                                    Indikator
                                </th>
                                <th className="border border-slate-500 w-[10%]" rowSpan={3}>
                                    Satuan
                                </th>
                                <th className="border border-slate-500" colSpan={triwulan.length * 3}>
                                    Tahun {yearconfig}
                                </th>
                            </tr>
                            <tr>
                                {
                                    triwulan.map((item, index) => {
                                        return (
                                            <th className="border border-slate-500" colSpan={3}>
                                                <span className="md:block hidden">{`Triwulan ${index+1}`}</span>
                                                <span className="md:hidden block">{`Tw ${index+1}`}</span>
                                            </th>
                                        )
                                    })
                                }
                            </tr>
                            <tr>
                                {
                                    triwulan.map((item, key) => {
                                        return (
                                            <>
                                                <th className="border border-slate-500" key={key}>
                                                    <span className="md:block hidden">Target</span>
                                                    <span className="md:hidden block">T</span>
                                                </th>
                                                <th className="border border-slate-500">
                                                    <span className="md:block hidden">Realisasi</span>
                                                    <span className="md:hidden block">R</span>
                                                </th>
                                                <th className="border border-slate-500">
                                                    <span className="md:block hidden">Capaian</span>
                                                    <span className="md:hidden block">C</span>
                                                </th>
                                            </>
                                        )
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                props.capaianPK.data ? renderSasaran(props.capaianPK.data) : 
                                <tr>
                                    <td className="border border-slate-500 py-2 px-5 bg-green-200" colSpan={15}>Loading...</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                    <span className="text-red-400 text-xs font-bold">
                        Notes : <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; *) Data triwulan IV
                    </span>
                </div>
                <div className="w-full px-10">
                </div>
            </div>
            <BackButton backLink={`/tahun/${yearconfig}/cpkess/listopd`} />
        </Layout>
    )
}

const renderSasaran = (data) =>{
    try {
        return (
            data.sasaran ? data.sasaran.map((item, keys) => {
                const indikator = renderIndikator(item.indikator)
                const sasaran = (
                    <>
                        <tr key={keys}>
                            <td className="border border-slate-500 font-bold py-2 px-5 bg-green-200" colSpan={15}>{item.nm_sasaran}</td>
                        </tr>
                        {
                            indikator
                        }
                    </>
                )
                return (sasaran)
            }) :
            <tr>
                <td className="border border-slate-500 py-2 px-5 bg-green-200 text-center text-lg font-bold" colSpan={15}>{data.message}</td>
            </tr>
        )
        
    } catch (error) {
        return (
            <tr>
            <td className="border border-slate-500 py-2 px-5 bg-green-200 text-center text-lg font-bold" colSpan={15}>No Data</td>
        </tr>
        )
    }
}

const renderIndikator = (list) => {
    return list?.map((val, keys) => {
        return (
            <tr key={keys}>
                <td className="border border-slate-500 p-2 text-center">{keys+1}</td>
                <td className="border border-slate-500 p-2">{val.nm_sasaran_indikator}</td>
                <td className="border border-slate-500 p-2 text-center"  dangerouslySetInnerHTML={{ __html: val.satuan }}></td>
                {
                    triwulan.map((item) => {
                        return (
                            <>
                                <td className="border border-slate-500 p-2 text-center">{val[`target_${item}`]}</td>
                                <td className="border border-slate-500 p-2 text-center">{val[`realisasi_${item}`]}</td>
                                <td className="border border-slate-500 p-2 text-center">{val[`capaian_${item}`]}</td>
                            </>
                        )
                    })
                }
            </tr>
        )
    })
}

const mapStateToProps = (state) => ({
    capaianPK: state.pkState.capaianPK
})

const mapDispatchToProps = {
    getCapaianPerjanjianKinerja: getCapaianPerjanjianKinerja_act
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect, memo)(DataCapaian_PK)