import React from 'react'
import { connect } from 'react-redux'

const fliper = (nowState) => {
    switch(nowState){
        case 1:
            return "animate__flipInX animate__slow" // Added animate__slow for smoother animation
        case 2:
            return "animate__flipInX animate__slow"
        case 3:
            return "animate__flipOutX" // Added animate__slow for smoother animation
        default:
            return "animate__flipInX animate__slow"
    }
}

const statsDom = (count, visitors, index) => {
    const data = [
        <div className={"bg-white py-1 px-4 text-center animate__animated " + fliper(count)}>
            Data Pengunjung
        </div>,
        <div className={"border rounded-lg bg-primaryGreen py-2 px-4 drop-shadow-md text-white text-sm flex justify-between " + fliper(count)}>
            <div className="text-center flex gap-1 items-center">
                <div className="h-2 w-2 bg-gradient-to-r from-orange-600 to-orange-800 rounded-full"></div>
                Online
            </div>
            <div className="text-center">{visitors.currently_online}</div>
        </div>,
        <div className={"border rounded-lg bg-red-600 py-2 px-4 drop-shadow-md text-white text-sm flex justify-between " + fliper(count)}>
            <div className="text-center">Hari ini</div>
            <div className="text-center">{visitors.today}</div>
        </div>,
        <div className={"border rounded-lg bg-orange-600 py-2 px-4 drop-shadow-md text-white text-sm flex justify-between " + fliper(count)}>
            <div className="text-center">Minggu ini</div>
            <div className="text-center">{visitors.this_week}</div>
        </div>,
        <div className={"border rounded-lg bg-slate-600 py-2 px-4 drop-shadow-md text-white text-sm flex justify-between " + fliper(count)}>
            <div className="text-center">Bulan ini</div>
            <div className="text-center">{visitors.this_month}</div>
        </div>,
        <div className={"border rounded-lg bg-violet-600 py-2 px-4 drop-shadow-md text-white text-sm flex justify-between " + fliper(count)}>
            <div className="text-center">Tahun ini</div>
            <div className="text-center">{visitors.this_year}</div>
        </div>,
        <div className={"border rounded-lg bg-amber-600 py-2 px-4 drop-shadow-md text-white text-sm flex justify-between "  + fliper(count)}>
            <div className="text-center">Total</div>
            <div className="text-center">{visitors.total}</div>
        </div>
    ]

    return data[index]
}

const VisitorsCounter = (props) => {
    const [count, setCount] = React.useState(1)
    const [index, setIndex] = React.useState(0)
    React.useEffect(() => {
        const intervalId = setInterval(() => {
            if(count  < 3){ setCount(count + 1) }
            else{ 
                setCount(1)
                if(index < 6){ setIndex( index + 1 ) }
                else{ setIndex(0) }
            }
        }, 750)
        // Membersihkan interval saat komponen unmount
        return () => clearInterval(intervalId);
    },[count, index])

    return (
        <div
            className='animate__animated animate__fadeIn grid grid-flow-row gap-2 w-64' // Added animate__slow for smoother fadeIn animation
        >
            { statsDom(count, props.visitors, index) }
        </div>
    )
}

const mapStateToProps = (state) => ({
    visitors: state.visitorsState.visitors.data
})

const withConnect = connect(mapStateToProps)

export default withConnect(VisitorsCounter)