import React from 'react'
import { Link } from 'react-router-dom'

const LaporIcon = () => {
  return (
    <a href="https://www.lapor.go.id/" target="_blank" className="flex justify-center items-center my-2 relative">
        <div className="bg-red-500 flex justify-between items-center gap-3 md:rounded-lg rounded-full py-1 md:px-3 px-1 -z-10 absolute animate-ping md:w-[65%] md:h-[70%] w-full h-full ">
            {/* <div className="md:w-28 w-5 h-5"></div> */}
        </div>
        <div className="bg-red-500 flex justify-between items-center gap-3 md:rounded-lg rounded-full py-1 md:px-3 px-1 ">
            <div className="w-7 h-full bg-white rounded-full overflow-hidden">
                <img src="/img/pengaduan-icon.png" alt="" className="object-contain" />
            </div>
            <div className="text-white text-xs font-bold hidden md:block">
                Layanan Aspirasi dan<br /> Pengaduan Online
            </div>
        </div>
    </a>
  )
}

export default LaporIcon