import { combineReducers } from 'redux';
import { homeState, authState, ikdState, ikpState, progulState, pkState, ikuState, visitorsState, cpkgubernurState } from './ducks';

export default combineReducers({
  homeState,
  authState,
  ikdState,
  ikpState,
  progulState,
  pkState,
  ikuState,
  visitorsState,
  cpkgubernurState
});
