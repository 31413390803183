import React, { useEffect, memo } from 'react'
import { connect } from 'react-redux';
import { compose } from 'redux';
import Layout from '../../components/Layout';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { getOpdPemberiHibah } from '../../../redux/ducks/home/actions';
import { numberFormatter } from '../../helper';
import CountUp from 'react-countup';

import { Pie } from 'react-chartjs-2'
import BackButton from '../../components/BackButton';



const DataOpdPemberiHibah = ({ OpdPemberiHibahState, getOpdPemberiHibah }) => {
  const [colorTr, setColorTr] = React.useState(null);
  const [searchFilter, setSearchFilter] = React.useState('')
  const { yearconfig } = useParams()
  const navigate = useNavigate()
  useEffect(() =>{
    getOpdPemberiHibah(yearconfig);
  },[yearconfig])
  
  
const breadCrumbsData = [
  {
    title: 'Home',
    isActive: true,
    goTo: '/tahun/'+yearconfig,
  },
  {
    title: `Data OPD Pemberi Hibah`,
    isActive: false,
    goTo: '',
  },
  // {
  //   title: `Detail Hibah OPD`,
  //   isActive: true,
  //   goTo: '/hibah/detail-hibah-opd/opdId',
  // },
];
const filteredBySearch = () => {
  if (searchFilter === '') {
    return OpdPemberiHibahState.data;
  } else return OpdPemberiHibahState.data.filter((items) => items.nama_skpd.toLowerCase().includes(searchFilter.toLowerCase()));
}
const recap = () => {
  let total_apbd_hibah_jml = 0
  let total_apbd_hibah_anggaran = 0
  let total_belumproses_jml = 0
  let total_belumproses_agr = 0
  let total_sedangproses_jml = 0
  let total_selesaiproses_jml = 0
  let total_selesaiproses_agr = 0
  OpdPemberiHibahState.data.forEach((item) => {
    total_apbd_hibah_jml = total_apbd_hibah_jml + parseInt(item.juml_hibah_apbd ? item.juml_hibah_apbd : 0)
    total_apbd_hibah_anggaran = total_apbd_hibah_anggaran + parseInt(item.anggaran_apbd ? item.anggaran_apbd : 0)
    total_belumproses_jml = total_belumproses_jml + parseInt(item.juml_hibah_belum_proses ? item.juml_hibah_belum_proses : 0) ;
    total_belumproses_agr = total_belumproses_agr + parseInt(item.realisasi_apbd_belum ? item.realisasi_apbd_belum : 0) ;
    total_sedangproses_jml = total_sedangproses_jml +  parseInt(item.juml_hibah_sedang_proses ? item.juml_hibah_sedang_proses : 0) ;
    total_selesaiproses_jml = total_selesaiproses_jml + parseInt(item.juml_hibah_selesai_proses ? item.juml_hibah_selesai_proses : 0);
    total_selesaiproses_agr = total_selesaiproses_agr + parseInt(item.realisasi_apbd ? item.realisasi_apbd : 0);
  })
  return {
    total_apbd_hibah_jml,
    total_apbd_hibah_anggaran,
    total_belumproses_jml,
    total_belumproses_agr,
    total_sedangproses_jml,
    total_selesaiproses_jml,
    total_selesaiproses_agr
  }
}

const dataHibah = {
  // labels: ['Target', 'direalisasikan'],
  datasets: [
    {
      label: 'Rp. ',
      data: [ recap().total_apbd_hibah_anggaran, recap().total_selesaiproses_agr],
      backgroundColor: ['#FF6F05', '#FFB883'],
      // borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)'],
      borderWidth: 1,
    },
  ],
};
return (
    <Layout
      loading={OpdPemberiHibahState.loadingGet}
      breadCrumbsData={breadCrumbsData}
    >
        
      <div className="bg-white rounded-xl py-1 px-3 pb-3 relative mb-5">
        <div className="bg-white py-3 px-5 rounded-md animate__animated animate__fadeIn">
          <div className="flex gap-3 mb-3 justify-center lg:w-1/2">
            
          </div>
          <div className="grid grid-rows-1 lg:grid-cols-12 gap-3">
            <div className="bg-white col-span-3 w-full pt-2 border border-primaryOrange rounded-lg lg:order-last">
              <div className="flex gap-4 flex-wrap justify-center p-3">
                <div className="flex justify-center items-center">
                  <div className="flex flex-col gap-2">
                    <div className="flex">
                      <div className="h-4 w-4 rounded-full bg-[#FF6F05]"></div>
                      <p className="text-xs pl-1 italic">Total Hibah</p>
                    </div>
                    <div className="flex">
                      <div className="h-4 w-4 rounded-full bg-[#FFB883]"></div>
                      <p className="text-xs pl-1 italic">Realisasi</p>
                    </div>
                  </div>
                </div>
                <div className="md:w-1/3 sm:w-1/2 w-full">
                  <Pie
                    data={dataHibah}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      color: 'black',
                      plugins: {
                        legend: {
                          labels: {
                            font: {
                              size: 14,
                              // weight: 'bold',
                            },
                          },
                        },
                        datalabels: {
                          display: false,
                        },
                      },
                    }}
                  />
                </div>
                <div className="md:flex hidden justify-center items-center">
                  <h1 className="font-bold text-center text-xl mb-3">Hibah</h1>
                  <img src="/img/hibahico.svg" className="w-full h-24" alt="" />
                </div>
              </div>
            </div>
            <div className="bg-white rounded-xl col-span-3">
              <div className="border border-primaryOrange py-2 px-3 pb-2 rounded-lg">
                <span className="text-black font-bold md:text-lg text-sm">Total Hibah Tahun {yearconfig}</span>
                <br />
                <span className="text-primaryGreen md:text-2xl text-xl font-bold md:tracking-widest">
                  Rp. {<CountUp duration={1.75} separator="." end={recap().total_apbd_hibah_anggaran} /> }
                </span>
                <p className="font-bold md:text-md text-sm mt-2 mb-2 text-right">/ { numberFormatter(recap().total_apbd_hibah_jml)} Penerima Hibah</p>
                
              </div>
            </div>
            <div className="bg-white rounded-xl col-span-3">
              <div className="border border-primaryOrange py-2 px-3 pb-2 rounded-lg">
                <span className="text-black md:text-lg text-sm font-bold">Sisa belum Realisasi</span>
                <br />
                <span className="text-primaryGreen md:text-2xl text-xl font-bold md:tracking-widest">
                  {' '}
                  Rp. {  <CountUp duration={1.75} separator="." end={recap().total_belumproses_agr} /> }
                </span>
                <p className="font-bold md:text-md text-sm mt-2 mb-2 text-right">/ { numberFormatter(recap().total_belumproses_jml)} Penerima Hibah</p>
              </div>
            </div>
            <div className="bg-white rounded-xl col-span-3">
              <div className="border border-primaryOrange py-2 px-3 pb-2 rounded-lg">
                <span className="text-black md:text-lg text-sm font-bold">Realisasi sampai dengan hari ini</span>
                <br />
                <span className="text-primaryGreen md:text-2xl text-xl font-bold md:tracking-widest">
                  {' '}
                  Rp. { <CountUp duration={1.75} separator="." end={recap().total_selesaiproses_agr} /> }
                </span>
                <p className="font-bold md:text-md text-sm mt-2 mb-2 text-right">/ { (numberFormatter(recap().total_selesaiproses_jml + recap().total_sedangproses_jml))} Penerima Hibah</p>
              </div>
            </div>
          </div>
        </div>
      </div>
        <div className="flex flex-col">
          <div className="flex-grow bg-white rounded-xl p-5 relative">
            <h1 className="text-lg font-bold   text-center">Data Realisasi Pemberian Hibah</h1>
            <h1 className="text-lg font-bold   text-center">Tahun {yearconfig}</h1>
            <h1 className="mb-1 text-sm text-slate-600 text-center">
              Sampai Dengan Hari ini
            </h1>
            <hr className="mb-3" />
            <div className="flex flex-col lg:flex-row xl:flex-row justify-between">
              <div className="py-2 w-full lg:w-[30%] xl:w-[30%]">
                <label for="table-search" className="sr-only">
                  Search
                </label>
                <div className="relative mt-1">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg
                      className="w-5 h-5 text-gray-500"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </div>
                  <input
                    type="text"
                    onChange={(e) => setSearchFilter(e.target.value)}
                    className="bg-gray-50 border border-gray-300 text-gray-800 text-sm font-bold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-1.5 placeholder:text-gray-900 "
                    placeholder="Cari berdasarkan nama OPD"
                  />
                </div>
              </div>
            </div>
            <div className="w-full overflow-x-auto">
              <table className="w-full table-auto text-xs mb-16">
                <thead className="bg-primaryGreen sticky top-0">
                  <tr>
                    <th className="border border-slate-500  w-[1%]" rowSpan={2}>
                      No
                    </th>
                    <th className="border border-slate-500 w-1/3" rowSpan={2}>
                      OPD
                    </th>
                    <th className="border border-slate-500 p-2" colSpan={2}>
                      APBD Hibah
                    </th>
                    <th className="border border-slate-500 p-2" colSpan={2}>
                      Belum Proses
                    </th>
                    {/* <th className="border border-slate-500 p-2" colSpan={2}>
                      Sedang Proses
                    </th> */}
                    <th className="border border-slate-500 p-2" colSpan={2}>
                      Realisasi
                    </th>
                  </tr>
                  <tr>
                    <th className="border border-slate-500 p-2">
                      Jumlah
                    </th>
                    <th className="border border-slate-500 p-2">
                      Anggaran
                    </th>
                    <th className="border border-slate-500 p-2">
                      Jumlah
                    </th>
                    <th className="border border-slate-500 p-2">
                      Anggaran
                    </th>
                    <th className="border border-slate-500 p-2">
                      Jumlah
                    </th>
                    <th className="border border-slate-500 p-2">
                      Anggaran
                    </th>
                    {/* <th className="border border-slate-500 p-2">
                      Jumlah
                    </th>
                    <th className="border border-slate-500 p-2">
                      Anggaran
                    </th> */}
                  </tr>
                </thead>
                <tbody>
                  {
                    filteredBySearch().map((val,keys) => {
                      
                      return (
                        <tr
                          key={keys}
                          onClick={() => setColorTr(keys)}
                          className={colorTr === keys ? 'bg-green-200' : 'cursor-pointer'}
                        >
                          <td className="border border-slate-500 p-2">{keys+1}</td>
                          <td className="border border-slate-500 p-2">
                          <Link
                            to={`/tahun/${yearconfig}/hibah/detail-hibah-opd/${val.skpd}/${val.nama_skpd}`}
                            className="text-blue-500 underline text-left text-xs font-bold"
                          >
                            {val.nama_skpd}
                          </Link>
                          </td>
                          <td className="border border-slate-500 p-2 text-right">{numberFormatter(val.juml_hibah_apbd ? val.juml_hibah_apbd : 0)}</td>
                          <td className="border border-slate-500 p-2 text-right">{numberFormatter(val.anggaran_apbd ? val.anggaran_apbd : 0)}</td>
                          <td className="border border-slate-500 p-2 text-right">{numberFormatter(val.juml_hibah_belum_proses ? val.juml_hibah_belum_proses : 0)}</td>
                          <td className="border border-slate-500 p-2 text-right">{numberFormatter(val.realisasi_apbd_belum ? val.realisasi_apbd_belum : 0)}</td>
                          {/* <td className="border border-slate-500 p-2 text-right">{numberFormatter(val.juml_hibah_sedang_proses ? val.juml_hibah_sedang_proses : 0)}</td>
                          <td className="border border-slate-500 p-2 text-right">0</td> */}
                          <td className="border border-slate-500 p-2 text-right">{
                            numberFormatter(val.juml_hibah_selesai_proses ? parseInt(val.juml_hibah_selesai_proses) + parseInt(val.juml_hibah_sedang_proses) : 0)
                          }</td>
                          <td className="border border-slate-500 p-2 text-right">{numberFormatter(val.realisasi_apbd ? val.realisasi_apbd : 0)}</td>
                        </tr>
                      )
                    })
                  }
                </tbody>
                <tfoot>
                  <tr className='bg-primaryGreen'>
                    <th className="border border-slate-500 p-2" colSpan={2}>Jumlah</th>
                    <th className="border border-slate-500 p-2 text-right">{numberFormatter(recap().total_apbd_hibah_jml)}</th>
                    <th className="border border-slate-500 p-2 text-right">{numberFormatter(recap().total_apbd_hibah_anggaran)}</th>
                    <th className="border border-slate-500 p-2 text-right">{numberFormatter(recap().total_belumproses_jml)}</th>
                    <th className="border border-slate-500 p-2 text-right">{numberFormatter(recap().total_belumproses_agr)}</th>
                    {/* <th className="border border-slate-500 p-2 text-right">{numberFormatter(total_sedangproses_jml)}</th>
                    <th className="border border-slate-500 p-2 text-right">0</th> */}
                    <th className="border border-slate-500 p-2 text-right">{numberFormatter(recap().total_selesaiproses_jml + recap().total_sedangproses_jml)}</th>
                    <th className="border border-slate-500 p-2 text-right">{numberFormatter(recap().total_selesaiproses_agr)}</th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
        <BackButton backLink={'/tahun/'+yearconfig} />
    </Layout>
  )
}

const mapStateToProps = (state) => ({
  OpdPemberiHibahState: state.homeState.opdPemberiHibah,
})

const mapDispatchToProps = {
  getOpdPemberiHibah
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect, memo)(DataOpdPemberiHibah)