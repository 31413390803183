import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import ScrollToTop from './scrolToTopPage';
import { Public } from './routes';
import { NotFound } from '../app/pages/error';
import { connect } from 'react-redux';
import { refillAuth } from '../redux/ducks/auth/action';
import 'animate.css';
import { withRefillAuth } from './hoc';
import { Home4, Maintenance, YearInit, YearConfig, HotlinePage } from './pages';

const App = () => {
    return (
        <BrowserRouter>
            <ScrollToTop>
                <Routes>
                    <Route key="year-init" path="" element={<YearInit />} />
                    <Route key="year-config" path='tahun/:yearconfig' element={<YearConfig />}>
                        <Route key="year-config" path='bapenda' element={<Maintenance />} />
                        {Public.map(({ key, name, Component, path }) => (
                            <Route key={key} path={path} element={<Component />} />
                        ))}
                    </Route> 
                    <Route key="hotline" path="hotline" element={<HotlinePage />} />
                    <Route key="not-found" path="/*" element={<NotFound />} />
                </Routes>
            </ScrollToTop>
        </BrowserRouter>
    );
};

const mapDispatchToProps = {
    refillAuth,
};

const withConnect = connect(false, mapDispatchToProps);

export default withConnect(withRefillAuth(App));
