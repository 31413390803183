import React from 'react';
import Layout from '../../components/Layout';
import { getGrafikPendapatan } from '../../../redux/ducks/home/actions';
import { connect } from 'react-redux';

import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { useNavigate, useParams } from 'react-router-dom';
import BackButton from '../../components/BackButton';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

function GrafikPendapatanPerOpd(props) {
  const navigate = useNavigate();
  const { yearconfig } = useParams()
  React.useEffect(() => {
    props.GrafikPendapatanPerOpd(yearconfig);
  }, [yearconfig]);

  const breadCrumbsData = [
    {
      title: 'Home',
      isActive: true,
      goTo: '/tahun/'+yearconfig,
    },
    {
      title: 'Detail Pendapatan',
      isActive: true,
      goTo: `/tahun/${yearconfig}/bappenda/detail-pendapatan`,
    },
    {
      title: 'Grafik Pendapatan per OPD',
      isActive: false,
      goTo: '',
    },
  ];

  const dataBar = {
    labels: props.dataGrafik.opd,
    datasets: [
      {
        axis: 'y',
        label: 'Persentase',

        data: props.dataGrafik.value,
        fill: false,
        backgroundColor: ['#819E43'],
        borderColor: ['#819E43'],
        borderWidth: 1,
        barPercentage: 0.9,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        ticks: {
          // Include a dollar sign in the ticks
          callback: function (value, index, ticks) {
            return props.dataGrafik.opd[index];
          },
          color: 'black',
          font: {
            family: 'Poppins, sans-serif',
            size: 12,
          },
        },
      },
      x: {
        ticks: {
          callback: function (value, index, ticks) {
            if (parseInt(value) >= 1000) {
              return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '%';
            } else {
              return value + '%';
            }
          },
        },
      },
    },
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 20,
      },
    },
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Grafik Pendapatan OPD Tahun '+yearconfig,
        color: 'black',
        font: {
          size: 18,
          family: 'Poppins, sans-serif',
        },
      },
      datalabels: {
        color: 'black',
        align: 'end',
        anchor: 'end',
        font: {
          size: 12,
        },
        formatter: function (value) {
          return `${value} %`;
        },
      },
    },
  };

  return (
    <Layout loading={props.loading} breadCrumbsData={breadCrumbsData}>
      <div className="bg-white p-2 rounded-lg animate__animated animate__fadeIn">
        <div className="flex justify-end">
          <div className="text-sm text-black font-medium flex flex-col lg:flex-row xl:flex-row pr-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-4 h-4 text-gray-500"
            >
              <path
                fillRule="evenodd"
                d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 6a.75.75 0 00-1.5 0v6c0 .414.336.75.75.75h4.5a.75.75 0 000-1.5h-3.75V6z"
                clipRule="evenodd"
              />
            </svg>
            <span className="text-xs text-gray-600 pl-1">terakhir update : {props.lastUpdateGrafik}</span>
          </div>
        </div>
        <div className="chartBox mb-5">
          <Bar data={dataBar} options={options} />
        </div>
      </div>
      <BackButton backLink={`/tahun/${yearconfig}/bappenda/detail-pendapatan`} />
    </Layout>
  );
}

const mapStateToProps = (state) => {
  return {
    dataGrafik: state.homeState.grafikPendapatanDaerah.data,
    lastUpdateGrafik: state.homeState.grafikPendapatanDaerah.last_update,
  };
};

const mapDispatchToProps = {
  GrafikPendapatanPerOpd: getGrafikPendapatan,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(GrafikPendapatanPerOpd);
