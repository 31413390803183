import React from 'react'
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getDetailHibahOpd } from '../../../redux/ducks/home/actions';
import Layout from '../../components/Layout';
import { useParams, useSearchParams } from 'react-router-dom';
import { numberFormatter } from '../../helper';
import BackButton from '../../components/BackButton';

const DetailHibahOpd = ({ getDetailHibahOpd, DetailHibahOpd }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [valueSelect, setValueSelect] = React.useState('')
  const [valueSearch, setValueSearch] = React.useState('')
  const { opdId, opdName, yearconfig } = useParams();
  const breadCrumbsData = [
    {
      title: 'Home',
      isActive: true,
      goTo: '/tahun/'+yearconfig,
    },
    {
      title: `Data OPD Pemberi Hibah`,
      isActive: true,
      goTo: `/tahun/${yearconfig}/hibah/opd-pemberi-hibah`,
    },
    {
      title: `Detail Hibah OPD`,
      isActive: false,
      goTo: '',
    },
  ];

  React.useEffect(() => {
    getDetailHibahOpd(opdId, yearconfig)
  },[opdId, yearconfig])

  const filteredData = () => {
    let filtered = DetailHibahOpd.data
    if(valueSelect !== ''){
      filtered = filtered.filter((items) => (
        items.status_pelaksanaan.toLowerCase() == valueSelect.toLowerCase()
      ))
    }
    if(valueSearch !== ''){
      filtered = filtered.filter((items) =>(
        items.lembaga_penerima_hibah.toLowerCase().includes(valueSearch.toLowerCase())
      ))
    }
    return filtered
  }
  let total_anggaran = 0;
  let total_anggaran_realisasi = 0;
  let total_anggaran_sisa = 0;
  const handleDynamicQueryString = (e) => {
    let index = e.nativeEvent.target.selectedIndex;
    setValueSelect(e.target.value);
    setSearchParams({ type: e.target.value });
  };
  const handleDynamicSearch = (e) => {
    let index = e.nativeEvent.target.selectedIndex;
    setValueSearch(e.target.value);
  };
  return (
    <Layout
      loading={DetailHibahOpd.loadingGet}
      breadCrumbsData={breadCrumbsData}
    >
      <div className="flex flex-col">
        <div className="flex-grow bg-white rounded-xl p-5 relative">
          <h1 className="text-lg font-bold text-center">Detail Realisasi Pemberian Hibah</h1>
          <h1 className="text-lg font-bold text-center">{opdName}</h1>
          <h1 className="text-lg font-bold text-center">Tahun {yearconfig}</h1>
          <h1 className="mb-1 text-center">
            Sampai Dengan Hari ini
          </h1>
          <hr className="mb-3" />
          <div className="flex flex-col lg:flex-row xl:flex-row justify-between">
            <div className="py-2 w-full lg:w-[30%] xl:w-[30%]">
              <label for="table-search" className="sr-only">
                Search
              </label>
              <div className="relative mt-1">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg
                    className="w-5 h-5 text-gray-500"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
                <input
                  type="text"
                  className="bg-gray-50 border border-gray-300 text-gray-800 text-sm font-bold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-1.5 placeholder:text-gray-900 "
                  placeholder="Cari berdasarkan nama Penerima Hibah"
                  value={valueSearch}
                  onChange={handleDynamicSearch}
                />
              </div>
            </div>
            <div className="py-2 w-full lg:w-[30%] xl:w-[30%]">
              <label for="table-search" className="sr-only">
                Search
              </label>
              <div className="relative mt-1">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-5 h-5 text-gray-500"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
                    />
                  </svg>
                </div>
                <select
                  className="bg-gray-50 border border-gray-300 text-gray-800 text-sm font-bold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-1.5 placeholder:text-gray-900"
                  value={valueSelect}
                  onChange={handleDynamicQueryString}
                >
                  <option value="">Semua</option>
                  <option value="belum">Belum Proses</option>
                  <option value="proses">Proses</option>
                  <option value="selesai">Selesai</option>
                </select>
              </div>
            </div>
          </div>
          <table className="w-full table-auto text-xs mb-16">
            <thead className="bg-primaryGreen sticky top-0">
              <tr>
                <th className="border border-slate-500 ">
                  No
                </th>
                <th className="border border-slate-500 w-1/5">
                  Nama Penerima Hibah
                </th>
                <th className="border border-slate-500 p-2 w-1/4">
                  Alamat Penerima Hibah
                </th>
                <th className="border border-slate-500 p-2">
                  Kategori Hibah
                </th>
                <th className="border border-slate-500 p-2">
                  Rencana Pelaksanaan
                </th>
                <th className="border border-slate-500 p-2">
                  Status
                </th>
                <th className="border border-slate-500 p-2">
                  Nilai Anggaran
                </th>
                <th className="border border-slate-500 p-2">
                  Realisasi Anggaran
                </th>
                <th className="border border-slate-500 p-2">
                  Sisa Belum Realisasi
                </th>
              </tr>
            </thead>
            <tbody>
              {
                filteredData().length > 0 ? filteredData().map((items, key) => {
                  total_anggaran = total_anggaran + parseInt(items.anggaran_apbd)
                  total_anggaran_realisasi = total_anggaran_realisasi + parseInt(items.anggaran_apbd_realisasi)
                  total_anggaran_sisa = total_anggaran_sisa + parseInt(items.sisa_belum_realisasi)
                  return (<tr key={key}>
                    <td className="border border-slate-500 p-4 text-right">{key+1}</td>
                    <td className="border border-slate-500 p-4">{items.lembaga_penerima_hibah}</td>
                    <td className="border border-slate-500 p-4">{items.alamat}</td>
                    <td className="border border-slate-500 p-4">{items.kategori}</td>
                    <td className="border border-slate-500 p-4">{items.bulan_realisasi}</td>
                    <td className="border border-slate-500 p-4 text-center">{items.status_pelaksanaan}</td>
                    <td className="border border-slate-500 p-4 text-right">{numberFormatter(items.anggaran_apbd)}</td>
                    <td className="border border-slate-500 p-4 text-right">{numberFormatter(items.anggaran_apbd_realisasi)}</td>
                    <td className="border border-slate-500 p-4 text-right">{numberFormatter(items.sisa_belum_realisasi)}</td>
                  </tr>)
                }) : (<tr>
                  <td colSpan="100%" className="border border-slate-500 p-4 text-center">Data Belum Tersedia</td>
                </tr>)
              }

              
            </tbody>
            <tfoot>
              <tr>
                <th className="border border-slate-500 p-2" colSpan={6}>Total</th>
                <th className="border border-slate-500 p-2 text-right">{numberFormatter(total_anggaran)}</th>
                <th className="border border-slate-500 p-2 text-right">{numberFormatter(total_anggaran_realisasi)}</th>
                <th className="border border-slate-500 p-2 text-right">{numberFormatter(total_anggaran_sisa)}</th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <BackButton backLink={`/tahun/${yearconfig}/hibah/opd-pemberi-hibah`} />
    </Layout>
  )
}

const mapStateToProps = (state) => ({
  DetailHibahOpd: state.homeState.detailHibahOpd,
  OpdPemberiHibah: state.homeState.opdPemberiHibah
})

const mapDispatchToProps = {
  getDetailHibahOpd
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect, React.memo)(DetailHibahOpd)
