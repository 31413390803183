import React, { useEffect, memo } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { getRealisasiBelanjaOPD, getPendapatanDaerah } from '../../../redux/ducks/home/actions'
import Layout from '../../components/Layout'
import { numberFormatter } from '../../helper'


const Home = ({ getRealisasiBelanjaOPD, getPendapatanDaerah, homeState }) => {
    useEffect(() => {
        getRealisasiBelanjaOPD()
        getPendapatanDaerah()
    },[getRealisasiBelanjaOPD, getPendapatanDaerah])
  return (
    <Layout>
        <div className="flex justify-center">
          <div className="flex flex-row py-10 w-2/3 justify-between">
            <div className="w-2/5 h-64 bg-primeblue rounded-xl drop-shadow-2xl">
              <div className="flex w-full justify-center">
                <div className="w-full pt-12 pb-4">
                  <h1 className="text-3xl text-white text-center mb-5">Pendapatan Daerah</h1>
                  <h2 className="2xl:text-xl xl:text-md text-sm text-white flex flex-row px-10">
                    <span className="w-1/3">Target</span>
                    <span>: Rp. {numberFormatter(homeState.pendapatanDaerah.target)}</span>
                  </h2>
                  <h2 className="2xl:text-xl xl:text-md text-sm text-white flex flex-row px-10">
                    <span className="w-1/3">Realisasi</span>
                    <span>: Rp. {numberFormatter(homeState.pendapatanDaerah.realisasi)}</span>
                  </h2>
                  <h2 className="2xl:text-xl xl:text-md text-sm text-white flex flex-row px-10">
                    <span className="w-1/3">Persentase</span>
                    <span>: {homeState.pendapatanDaerah.persentase} %</span>
                  </h2>
                </div>
              </div>
            </div>
            <div className="w-2/5 h-64 bg-primegreen rounded-xl drop-shadow-2xl">
              <div className="flex">
                  <div className="w-full pt-12 pb-4">
                    <h1 className="text-3xl text-white text-center mb-5">Realisasi Belanja OPD</h1>
                    <h2 className="2xl:text-xl xl:text-md text-sm text-white flex flex-row px-10">
                    <span className="w-1/3">PAGU APBD</span>
                    <span>: Rp. {numberFormatter(homeState.realisasiBelanjaOPD.target)}</span>
                  </h2>
                  <h2 className="2xl:text-xl xl:text-md text-sm text-white flex flex-row px-10">
                    <span className="w-1/3">Realisasi</span>
                    <span>: Rp. {numberFormatter(homeState.realisasiBelanjaOPD.realisasi)}</span>
                  </h2>
                  <h2 className="2xl:text-xl xl:text-md text-sm text-white flex flex-row px-10">
                    <span className="w-1/3">Persentase</span>
                    <span>: {homeState.realisasiBelanjaOPD.persentase} %</span>
                  </h2>
                  </div>
                </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center">
          <div className="grid grid-cols-3 py-10 w-5/6 gap-16">
              <div className="h-64 bg-white rounded-xl drop-shadow-2xl">
                <div className="w-full py-4 bg-primeblue rounded-t-xl">
                  <h1 className="text-2xl text-white text-center">e-Purchasing</h1>
                </div>
                <div className="flex py-3">
                  <ul className="pl-8 list-disc">
                    <li>Paket : <span>n/a</span></li>
                    <li>Nilai : <span>1.000.000.000.000</span></li>
                    <li>Belum Proses : <span>n/a</span></li>
                    <li>Sedang Proses : <span>n/a</span></li>
                    <li>Selesai Proses : <span>n/a</span></li>
                    <li>Selesai Pelaksanaan : <span>n/a</span></li>
                  </ul>
                </div>
              </div>
              <div className="h-64 bg-white rounded-xl drop-shadow-2xl">
                <div className="w-full py-4 bg-primeblue rounded-t-xl">
                  <h1 className="text-2xl text-white text-center">Tendering</h1>
                </div>
                <div className="flex py-3">
                  <ul className="pl-8 list-disc">
                    <li>Paket : <span>n/a</span></li>
                    <li>Nilai : <span>n/a</span></li>
                    <li>Belum Proses : <span>n/a</span></li>
                    <li>Sedang Proses : <span>n/a</span></li>
                    <li>Selesai Proses : <span>n/a</span></li>
                    <li>Selesai Pelaksanaan : <span>n/a</span></li>
                  </ul>
                </div>
              </div>
              <div className="h-64 bg-white rounded-xl drop-shadow-2xl">
                <div className="w-full py-4 bg-primegreen rounded-t-xl">
                  <h1 className="text-2xl text-white text-center">Non-Tendering dan Non e-Purchasing</h1>
                </div>
                <div className="flex py-3">
                  <ul className="pl-8 list-disc">
                    <li>Paket : <span>n/a</span></li>
                    <li>Nilai : <span>n/a</span></li>
                    <li>Belum Proses : <span>n/a</span></li>
                    <li>Sedang Proses : <span>n/a</span></li>
                    <li>Selesai Proses : <span>n/a</span></li>
                    <li>Selesai Pelaksanaan : <span>n/a</span></li>
                  </ul>
                </div>
              </div>
              {/* <div className="h-64 rounded-xl drop-shadow-2xl bg-white">
                <div className="w-full py-4 bg-primegreen rounded-t-xl">
                  <h1 className="text-2xl text-white text-center">Hibah</h1>
                </div>
              </div> */}
            </div>
        </div>
    </Layout>
  )
}

const mapStateToProps = (state) => ({
    homeState: state.homeState
})

const mapDispatchToProps = {
    getRealisasiBelanjaOPD,
    getPendapatanDaerah
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect, memo)(Home)