import * as types from "./types"

const initialState = {
    listOPD: {
        loadingGet: false,
        errorGet: false,
        messageGet: "",
        data: [],
    },
    capaianPK: {
        loadingGet: false,
        errorGet: false,
        messageGet: "",
        data: []
    }
}

export default function pkReducer(state = initialState, actions){
    switch(actions.type){
        case types.GET_LISTOPD_PK_START:
            return {
                ...state,
                listOPD:{
                    ...state.listOPD,
                    loadingGet: true,
                    errorGet: false,
                    messageGet: "loading"
                }
            }
        case types.GET_LISTOPD_PK_SUCCESS:
            return {
                ...state,
                listOPD: {
                    ...state.listOPD,
                    loadingGet: false,
                    errorGet: false,
                    messageGet: actions.payload.message,
                    data: actions.payload.data
                }
            }
        case types.GET_LISTOPD_PK_FAILED:
            return {
                ...state,
                listOPD: {
                    ...state.listOPD,
                    loadingGet: false,
                    errorGet: true,
                    messageGet: actions.payload.message,
                }
            }
        case types.GET_CAPAIAN_PK_START:
            return {
                ...state,
                capaianPK:{
                    ...state.capaianPK,
                    loadingGet: true,
                    errorGet: false,
                    messageGet: "loading"
                }
            }
        case types.GET_CAPAIAN_PK_SUCCESS:
            return {
                ...state,
                capaianPK: {
                    ...state.capaianPK,
                    loadingGet: false,
                    errorGet: false,
                    messageGet: actions.payload.message,
                    data: actions.payload.data ? actions.payload.data : []
                }
            }
        case types.GET_CAPAIAN_PK_FAILED:
            return {
                ...state,
                capaianPK: {
                    ...state.capaianPK,
                    loadingGet: false,
                    errorGet: true,
                    messageGet: actions.payload.message,
                }
            }

        default:
            return state;
    }
}