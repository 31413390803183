import React, { useEffect, memo } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getRealisasiBelanjaOPD, getPendapatanDaerah, getRealisasiPbjProses } from '../../../redux/ducks/home/actions';
import Layout from '../../components/Layout';
import { numberFormatter } from '../../helper';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Pie } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';

import CountUp from 'react-countup';

ChartJS.register(ArcElement, Tooltip, Legend);

const Home2 = ({ getRealisasiBelanjaOPD, getPendapatanDaerah, getRealisasiPbjProses, homeState }) => {
  const navigate = useNavigate();
  useEffect(() => {
    getRealisasiBelanjaOPD();
    getPendapatanDaerah();
    getRealisasiPbjProses();
  }, [getRealisasiBelanjaOPD.getPendapatanDaerah, getRealisasiPbjProses]);

  const dataPendapatan = {
    labels: ['Target', 'direalisasikan'],
    datasets: [
      {
        label: 'Rp. ',
        data: [homeState?.pendapatanDaerah?.target, homeState?.pendapatanDaerah?.realisasi],
        backgroundColor: ['rgb(255,159,64)', 'rgb(255,64,105)'],
        // borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)'],
        borderWidth: 1,
      },
    ],
  };

  const dataRealisasiBelanjaOpd = {
    // labels: ['Target', 'direalisasikan'],
    datasets: [
      {
        label: 'Rp. ',
        data: [homeState?.realisasiBelanjaOPD?.target, homeState?.realisasiBelanjaOPD?.realisasi],
        backgroundColor: ['rgb(54,162,235)', 'rgb(255,64,105)'],
        // borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)'],
        borderWidth: 1,
      },
    ],
  };

  const _toBappendDetail = () => navigate('/bappenda/detail-pendapatan');

  const _toSimbangdaDetail = () => navigate('/simbangda/detail-belanja-opd');

  const breadCrumbsData = [
    {
      title: 'Home',
      isActive: false,
      goTo: '',
    },
  ];

  const checkAnd = (title) => {
    return title.replace('&', 'dan');
  };

  return (
    <Layout loading={homeState.pendapatanDaerah.loadingGet} breadCrumbsData={breadCrumbsData}>
      <div className="w-full p-5 bg-white rounded-lg flex gap-5 flex-col lg:flex-row xl:flex-row">
        <div
          onClick={_toBappendDetail}
          className="bg-primeblue rounded-md drop-shadow-2xl py-5 px-2 cursor-pointer w-full h-1/2"
        >
          <div className="flex flex-col md:flex-row lg:flex-row  px-2">
            <div className=" lg:w-[80%] xl:w-[80%]">
              <h1 className="text-2xl text-white text-left mb-5">Pendapatan Daerah</h1>
              <h2 className="2xl:text-md xl:text-md text-sm text-white flex flex-row ">
                <span className="w-1/3">Target</span>

                <span>
                  : {numberFormatter(homeState.pendapatanDaerah.target)}
                  {/* : Rp. <CountUp duration={1.75} separator="." end={homeState.pendapatanDaerah.target} /> */}
                </span>
              </h2>
              <h2 className="2xl:text-md xl:text-md text-sm text-white flex flex-row mt-2">
                <span className="w-1/3">Realisasi</span>
                <span>
                  : {numberFormatter(homeState.pendapatanDaerah.realisasi)}
                  {/* : Rp. <CountUp duration={1.75} separator="." end={homeState.pendapatanDaerah.realisasi} /> */}
                </span>
              </h2>
              <h2 className="2xl:text-md xl:text-md text-sm text-white flex flex-row mt-2">
                <span className="w-1/3">Persentase</span>
                <span>: {homeState.pendapatanDaerah.persentase} %</span>
              </h2>
              {/* <h2 className="text-md text-white flex flex-row mt-10">
                <span className="w-1/3">Terakhir Update</span>
                <span>: {homeState.pendapatanDaerah.last_update}</span>
              </h2> */}
              <div className="text-sm text-white font-medium flex flex-col lg:flex-row xl:flex-row mb-1 mt-16">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-6 h-6 text-gray-200"
                >
                  <path
                    fillRule="evenodd"
                    d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 6a.75.75 0 00-1.5 0v6c0 .414.336.75.75.75h4.5a.75.75 0 000-1.5h-3.75V6z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="mt-1 pl-1">{homeState.pendapatanDaerah.last_update}</span>
              </div>
            </div>
            {/* <div className='w-full lg:w-60 lg:h-60  xl:w-60 xl:h-60'> */}
            <div className="w-full lg:w-72 lg:h-56  xl:w-72 xl:h-56">
              <Pie
                data={dataPendapatan}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  color: 'black',
                  plugins: {
                    legend: {
                      labels: {
                        font: {
                          size: 14,
                        },
                      },
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>
        <div
          onClick={_toSimbangdaDetail}
          className="bg-green-600 rounded-lg drop-shadow-2xl p-5 cursor-pointer h-1/2 w-full"
        >
          <div className="flex flex-col md:flex-row lg:flex-row px-2 ">
            <div className=" lg:w-3/5 xl:w-3/5">
              <h1 className="text-2xl text-white text-left mb-5">Realisasi Belanja OPD</h1>
              <h2 className="2xl:text-md xl:text-md text-sm text-white flex flex-row ">
                <span className="w-1/3">Pagu</span>
                <span>
                  : {numberFormatter(homeState.realisasiBelanjaOPD.target)}
                  {/* : Rp. <CountUp duration={1.75} separator="." end={homeState.realisasiBelanjaOPD.target} /> */}
                </span>
              </h2>
              <h2 className="2xl:text-md xl:text-md text-sm text-white flex flex-row mt-2">
                <span className="w-1/3">Realisasi</span>
                <span>
                  : {numberFormatter(homeState.realisasiBelanjaOPD.realisasi)}
                  {/* : Rp. <CountUp duration={1.75} separator="." end={homeState.realisasiBelanjaOPD.realisasi} /> */}
                </span>
              </h2>
              <h2 className="2xl:text-md xl:text-md text-sm text-white flex flex-row mt-2">
                <span className="w-1/3">Persentase</span>
                <span>: {homeState.realisasiBelanjaOPD.persentase} %</span>
              </h2>
              {/* <h2 className="text-md text-white flex flex-row mt-10">
                <span className="w-1/3">Terakhir Update</span>
                <span>: {homeState.realisasiBelanjaOPD.last_update}</span>
              </h2> */}
              <div className="text-sm text-white font-medium flex flex-col lg:flex-row xl:flex-row mb-1 mt-16">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-6 h-6 text-gray-200"
                >
                  <path
                    fillRule="evenodd"
                    d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 6a.75.75 0 00-1.5 0v6c0 .414.336.75.75.75h4.5a.75.75 0 000-1.5h-3.75V6z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="mt-1 pl-1">{homeState.realisasiBelanjaOPD.last_update}</span>
              </div>
            </div>
            <div className="w-full lg:w-72 lg:h-56  xl:w-72 xl:h-56">
              <Pie
                data={dataRealisasiBelanjaOpd}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  color: 'black',
                  plugins: {
                    legend: {
                      labels: {
                        font: {
                          size: 14,
                          // weight: 'bold',
                        },
                      },
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className={`p-5 mt-5 rounded-lg ${
          homeState.realisasiPbjProses.data.length === 0 ? `hidden` : `bg-white animate__animated animate__fadeIn`
        }`}
      >
        <div className="grid grid-rows-1 lg:grid-cols-4 xl:grid-cols-4 2xl:gird-cols-4 gap-5">
          {homeState.realisasiPbjProses.data.map((items, keys) => {
            return (
              <div className="bg-white animate__animated animate__fadeIn">
                <div
                  onClick={() => {
                    if (items.type !== 0) {
                      navigate(`sipedal/detail-sipedal-opd?type=${items.type}&typeName=${checkAnd(items.title)}`);
                    }
                  }}
                  className="bg-white drop-shadow-2xl  cursor-pointer"
                >
                  <div className="flex flex-col">
                    <div className={`w-full py-4 px-2 ${items.bg}  rounded-t-md`}>
                      <h1 className="text-sm text-white text-center">{items.title}</h1>
                    </div>
                    <div className=" py-1 px-2">
                      <ul>
                        {items.data.map((subItems, keysSub) => {
                          return (
                            <li className="mb-1">
                              <div className={`flex flex-col lg:flex-row xl:flex-row px-2 `}>
                                <div className="w-[60%] text-sm font-medium">{subItems.title}</div>
                                <div className="w-[50%]">
                                  <span className={` text-right text-xs rounded-lg float-right`}>
                                    {numberFormatter(subItems.value)}
                                  </span>
                                </div>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className={`flex justify-between   px-3 py-2 rounded-b-md  text-white ${items.bgFooter}`}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-4 h-4 text-gray-200"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 6a.75.75 0 00-1.5 0v6c0 .414.336.75.75.75h4.5a.75.75 0 000-1.5h-3.75V6z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <p className="font-bold text-xs text-right">{items.last_update}</p>
                </div>
              </div>
            );
          })}
        </div>
        {/* <div className="flex justify-center">
          <button className="py-2 px-5 bg-primeblue mt-3  rounded-lg text-white">Lihat Rincian Per Opd</button>
        </div> */}
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  homeState: state.homeState,
});

const mapDispatchToProps = {
  getRealisasiBelanjaOPD,
  getPendapatanDaerah,
  getRealisasiPbjProses,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(Home2);
