import React from 'react'
import { NavLink } from 'react-router-dom';
const PointMenu = ({initial, label, link, isActive = false}) => {
  return (
    <>
        <NavLink to={link} 
            className={`cursor-pointer flex flex-row gap-3 p-2 sm:w-[221px] w-[170px] mx-1 group rounded-lg items-center ` + 
                (isActive ? `bg-green-700 text-white font-bold` : `bg-primaryGreen hover:bg-green-700 text-white hover:font-extrabold`)}>
            <div className="flex items-center justify-center flex-shrink-0">
                <span 
                    className={`rounded-full p-1.5 sm:text-base text-sm sm:w-11 sm:h-11 flex items-center justify-center bg-white text-green-500`}>
                        {initial}
                </span>
            </div>
            <span className="sm:text-sm text-xs flex-grow w-3/5">
                {label}
            </span>
        </NavLink>
    </>
  )
}

export default PointMenu