import { configureStore } from "@reduxjs/toolkit";
import * as reducers from './reducers'
import Api from "../api";

const store = configureStore({
    reducer: reducers.default,
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        thunk: {
            extraArgument: new Api()
        }
    })
})

export default store