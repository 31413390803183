import React from 'react';
import Layout from '../../components/Layout';
import { numberFormatter } from '../../helper';
import { useParams, useSearchParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { getDetailPakerOpdPbj } from '../../../redux/ducks/home/actions';
import Moment from 'react-moment';
import { WithIsPrivate } from '../../hoc';
import BackButton from '../../components/BackButton';

const DetailPaketOpd = (props) => {
  const [colorTr, setColorTr] = React.useState(null);
  const [filterData, setFilterData] = React.useState('');
  const [valueSelect, setValueSelect] = React.useState('');
  const { idsatker, namasatker, yearconfig } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();

  const type = searchParams.get('type');
  const typeName = searchParams.get('typeName');

  React.useEffect(() => {
    props.getDetailPaket(idsatker, yearconfig);
  }, [idsatker, yearconfig]);

  React.useEffect(() => {
    setValueSelect(type);
  }, []);

  const searchByNamaPaketAndJenisTrans = () => {
    let datapaket = props.dataPaket;
    if (valueSelect !== '') {
      datapaket = datapaket.filter((items) => {
        return items.jenis_transaksi == valueSelect;
      });
    }

    if (filterData == '') {
      return datapaket;
    } else return datapaket.filter((items) => items.namapaket.toLowerCase().includes(filterData.toLowerCase()));
  };

  const ucWord = (text) => {
    return text.toLowerCase().replace(/\b[a-z]/g, function (letter) {
      return letter.toUpperCase();
    });
  };
  const checkAnd = (title) => {
    return title.replace('dan', '&');
  };
  const breadCrumbsData = [
    {
      title: 'Home',
      isActive: true,
      goTo: '/',
    },
    {
      title: `Data Realisasi ${checkAnd(typeName)}`,
      isActive: true,
      goTo: `/tahun/${yearconfig}/sipedal/detail-sipedal-opd?type=${type}&typeName=${typeName}`,
    },
    {
      title: `Paket ${ucWord(namasatker)} ${checkAnd(typeName)}`,
      isActive: false,
      goTo: '',
    },
  ];
  const handleDynamicQueryString = (e) => {
    let index = e.nativeEvent.target.selectedIndex;
    setValueSelect(e.target.value);
    setSearchParams({ type: e.target.value, typeName: checkAndReverse(e.nativeEvent.target[index].text) });
  };

  const checkAndReverse = (title) => {
    return title.replace('&', 'dan');
  };

  let totalPagu = 0
  let totalKontrak = 0

  return (
    <Layout loading={props.loading} breadCrumbsData={breadCrumbsData}>
      <div className="flex flex-col">
        <div className="flex-grow relative bg-white rounded-xl p-5 mt-3">
          <h1 className="text-lg font-bold  text-center">DETAIL PAKET {namasatker} </h1>
          <p className="text-lg font-bold text-center">Tahun {yearconfig}</p>
          <h1 className="text-sm text-slate-600 text-center">
            Sampai Dengan {props.loading ? '' : <Moment format="D MMMM YYYY">{props.timestamp}</Moment>}
          </h1>
          <hr className="mb-3" />
          {/* <div className="p-2 flex flex-col md:flex-row lg:flex-row bg-gray-200 shadow-md mt-3 rounded-md">
            <p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 text-orange-500"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M18 10c0 3.866-3.582 7-8 7a8.841 8.841 0 01-4.083-.98L2 17l1.338-3.123C2.493 12.767 2 11.434 2 10c0-3.866 3.582-7 8-7s8 3.134 8 7zM7 9H5v2h2V9zm8 0h-2v2h2V9zM9 9h2v2H9V9z"
                  clip-rule="evenodd"
                />
              </svg>
            </p>
            <p className="text-xs text-gary-700 font-bold ">
              Data yang ditampilkan terakhir update <span className="font-bold text-red-500">{props.timestamp}</span>
            </p>
          </div> */}
          <div className="flex flex-col lg:flex-row xl:flex-row justify-between">
            <div className="py-2 w-full lg:w-[30%] xl:w-[30%]">
              <label for="table-search" className="sr-only">
                Search
              </label>
              <div className="relative mt-1">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg
                    className="w-5 h-5 text-gray-500"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
                <input
                  type="text"
                  onChange={(e) => setFilterData(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-800 text-sm font-bold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-1.5 placeholder:text-gray-900 "
                  placeholder="Cari berdasarkan nama OPD"
                />
              </div>
            </div>
            <div className="py-2 w-full lg:w-[30%] xl:w-[30%]">
              <label for="table-search" className="sr-only">
                Search
              </label>
              <div className="relative mt-1">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-5 h-5 text-gray-500"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
                    />
                  </svg>
                </div>
                <select
                  value={valueSelect}
                  className="bg-gray-50 border border-gray-300 text-gray-800 text-sm font-bold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-1.5 placeholder:text-gray-900"
                  onChange={handleDynamicQueryString}
                >
                  <option value="3">E-Purchasing</option>
                  <option value="1">Tendering</option>
                  <option value="2">Non-Tendering & Non E-Purchasing</option>
                  <option value="">Keseluruhan</option>
                </select>
              </div>
            </div>
          </div>
          <table className="w-full table-auto text-xs ">
            <thead className="bg-primaryGreen sticky top-0">
              <tr>
                <th className="border border-slate-500 py-5">No</th>
                <th className="border border-slate-500 ">Nama Paket</th>
                <th className="border border-slate-500">Pagu</th>
                <th className="border border-slate-500 ">Kontrak</th>
                <th className="border border-slate-500 ">Metode</th>
                <th className="border border-slate-500 ">PPK</th>
                <th className="border border-slate-500 ">Proses</th>
              </tr>
            </thead>
            <tbody>
              {searchByNamaPaketAndJenisTrans().map((items, keys) => {
                totalPagu = totalPagu + parseInt(items.jumlahpagu ? items.jumlahpagu : 0)
                totalKontrak = totalKontrak + parseInt(items.nilai_kontrak !== null ? items.nilai_kontrak : 0)
                return (
                  <tr
                    key={keys}
                    onClick={() => setColorTr(keys)}
                    className={colorTr === keys ? 'bg-green-200' : 'cursor-pointer'}
                  >
                    <td className="border border-slate-500 text-center p-3 w-[1%]">{keys + 1}</td>
                    <td className="border border-slate-500 py-3 px-2 w-[25%] font-bold">{items.namapaket}</td>
                    <td className="border border-slate-500 text-right font-bold p-2 ">
                      {numberFormatter(items.jumlahpagu)}
                    </td>
                    <td className="border border-slate-500 text-right font-bold p-2">
                      {items.nilai_kontrak !== null ? numberFormatter(items.nilai_kontrak) : ''}
                    </td>
                    <td className="border border-slate-500 text-center font-bold p-2 ">{items.metodepengadaan}</td>
                    <td className="border border-slate-500 text-center font-bold p-2 ">{items.ppk}</td>
                    <td className="border border-slate-500 text-center font-bold p-2 ">{items.proses}</td>
                  </tr>
                );
              })}
              <tr></tr>
            </tbody>
            <tfoot>
              <tr>
                <th colspan="2" className="border border-slate-500 py-5">Jumlah</th>
                <th className="border border-slate-500 py-5 text-right p-2">{ numberFormatter(totalPagu) }</th>
                <th className="border border-slate-500 py-5 text-right p-2">{ numberFormatter(totalKontrak) }</th>
                <th colspan="3" className="border border-slate-500 py-5">&nbsp;</th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <BackButton backLink={`/tahun/${yearconfig}/sipedal/detail-sipedal-opd?type=${type}&typeName=${typeName}`} />
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  dataPaket: state.homeState.pbjDetailPaketOpd.data,
  loading: state.homeState.pbjDetailPaketOpd.loadingGet,
  error: state.homeState.pbjDetailPaketOpd.errorGet,
  timestamp: state.homeState.pbjDetailPaketOpd.last_update,
});

const mapDispatchToProps = {
  getDetailPaket: getDetailPakerOpdPbj,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(DetailPaketOpd);
