import * as types from './types'

const getIku_act = () => async (dispatch, getState, Api) => {
    dispatch({ type: types.GET_IKU_START })
    const response = await Api.getIndikatorKinerjaUtama();
    if (response.status !== "failed") {
        dispatch({
            type: types.GET_IKU_SUCCESS,
            payload: {
                data: response.data.result.data,
                timestamp: response.data.timestamp,
                message: response.data.result.message,
                author: response.data.author
            },
        });
    } else {
        dispatch({
            type: types.GET_IKU_FAILED,
            payload: {
                message: response.error,
            },
        });
    }
}


export {
    getIku_act
}