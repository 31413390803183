import axios from 'axios';
const URL_SIMBANGDA = 'https://simbangda.sumbarprov.go.id/integrated/api';
// const URL_EBAPPENDAIP = 'http://182.253.192.84:8556/API/2';
// const URL_PBJ = 'https://sipedal.sumbarprov.go.id/api';
// const BASE_URL = 'https://testing3.sumbarprov.go.id/dpservices';
const INTERNAL_URL = 'https://admin-dashboard.sumbarprov.go.id/api';
const bapendaForwardReqUrl = 'https://admin-dashboard.sumbarprov.go.id/api/getsource/bapenda'
let HEADER_API_KEY = null;
let BASE_URL = INTERNAL_URL;
// const BASE_URL = 'http://127.0.0.1:8000';

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  };

class Api {
  bappendaKey = 'bapenda@02#2022';
  PBJ_KEY = 'c230af71-8705-4868-bcc6-c1356b2c2902';

  async getSettingApi(){
    try {
      const { status, data } = await axios.get(`https://admin-dashboard.sumbarprov.go.id/api/settingapi`);
      if (status === 200){
        HEADER_API_KEY = {
          headers: data.result.headers
        }
        BASE_URL = data.result.url

        // Store header and base URL to cookie with lifetime of 10 minutes
        const expirationDate = new Date();
        expirationDate.setTime(expirationDate.getTime() + (10 * 60 * 1000)); // Set cookie to expire in 10 minutes

        document.cookie = `HEADER_API_KEY=${JSON.stringify(data.result.headers)}; expires=${expirationDate.toUTCString()}; path=/`;
        document.cookie = `BASE_URL=${data.result.url}; expires=${expirationDate.toUTCString()}; path=/`;

      }
      else{
        HEADER_API_KEY = null
        BASE_URL = INTERNAL_URL;
      }
    } catch (error) {
      console.log(error.message);
      HEADER_API_KEY = null
      BASE_URL = INTERNAL_URL;
    }
  }
  
  async authentication(payload) {
    try {
      const { status, data } = await axios.post(`${BASE_URL}/auth`, payload);
      if (status === 200) return { data, error: null };
    } catch (error) {
      return {
        status: 'failed',
        error: error.message,
      };
    }
  }

  async loadsettingApi(){
      const cookieBaseUrl = getCookie('BASE_URL');
      const cookieHeaders = getCookie('HEADER_API_KEY');

      if (cookieBaseUrl){
        BASE_URL = cookieBaseUrl;
        if (cookieHeaders) HEADER_API_KEY = { headers: JSON.parse(cookieHeaders) };
      }
      else await this.getSettingApi()
  }

  async getRealisasiBelanjaOPD(tahun = "") {
    try {
      await this.loadsettingApi()
      tahun = "/"+tahun
      // if(tahun === "/2023"){ tahun = "" }

      const { status, data } = await axios.get(`${BASE_URL}/simbangda/getrealisasibelanjaopd${tahun}`, HEADER_API_KEY);
      if (status === 200) return { ...data, error: null };
    } catch (error) {
      return {
        status: 'failed',
        error: error.message,
      };
    }
  }
  async getRealisasiBelanjaOPD_perbulan(bulan = "", tahun = "") {
    try {  
      const { status, data } = await axios.get(`${URL_SIMBANGDA}/dashboard_pembangunan/data_opd?bulan=${bulan}&tahun=${tahun}`);
      if (status === 200) return { ...data, error: null };
    } catch (error) {
      return {
        status: 'failed',
        error: error.message,
      };
    }
  }

  async getgrafikSimbangda(idopd, tahun) {
    try {
      await this.loadsettingApi()
      let url =
        typeof idopd !== 'undefined'
          ? `/simbangda/getgrafikpencapaianopd/${idopd}/${tahun}`
          : `/simbangda/getgrafikpencapaianopd`;
      url = `/simbangda/getgrafikpencapaianopd/${idopd}/${tahun}`
      const { status, data } = await axios.get(`${BASE_URL}${url}`, HEADER_API_KEY);
      if (status === 200) return { ...data, error: null };
    } catch (error) {
      return {
        status: 'failed',
        error: error.message,
      };
    }
  }

  async getRealisasiBelanjaPerOPD(tahun) {
    try {
      await this.loadsettingApi()
      tahun = "/"+tahun
      // if(tahun === "/2023"){ tahun = "" }
      const { status, data } = await axios.get(`${BASE_URL}/simbangda/getlistrealisasibelanjaopd${tahun}`, HEADER_API_KEY);
      if (status === 200) return { ...data, error: null };
    } catch (error) {
      return {
        status: 'failed',
        error: error.message,
      };
    }
  }

  async getRealisasiKegiatanPerOPD(idopd, tahun) {
    try {
      await this.loadsettingApi()
      tahun = "/"+tahun
      // if(tahun === "/2023"){ tahun = "" }
      const { status, data } = await axios.get(`${BASE_URL}/simbangda/getrealisasikegiatanopd/${idopd}${tahun}`, HEADER_API_KEY);
      if (status === 200) return { ...data, error: null };
    } catch (error) {
      return {
        status: 'failed',
        error: error.message,
      };
    }
  }

  async getRealisasiProsesPbj(tahun) {
    try {
      await this.loadsettingApi()
      let body = {
        tahun: tahun
      };
      const { status, data } = await axios.post(`${BASE_URL}/sipedal/getrealisasipbj`, body, HEADER_API_KEY);
      if (status === 200) return { ...data, error: null };
    } catch (error) {
      return {
        status: 'failed',
        error: error.message,
      };
    }
  }

  async getPendapatanDaerah(tahun) {
    try {
      await this.loadsettingApi()
      const { status, data } = await axios.get(`${BASE_URL}/bapenda/getpendapatan/${tahun}`, HEADER_API_KEY);
      if (status === 200) return { data, error: null };
    } catch (error) {
      return {
        status: 'failed',
        error: error.message,
      };
    }
  }
  async getAllPendapatan(tahun) {
    try {
      await this.loadsettingApi()
      const { status, data } = await axios.get(`${BASE_URL}/bapenda/getdetailpendapatan/${tahun}`, HEADER_API_KEY);
      if (status === 200) return { data, error: null };
    } catch (error) {
      return {
        status: 'failed',
        error: error.message,
      };
    }
  }
  async getGrafikPendapatan(tahun) {
    try {
      await this.loadsettingApi()
      const { status, data } = await axios.get(`${BASE_URL}/bapenda/getgrafikpendapatan/${tahun}`, HEADER_API_KEY);
      if (status === 200) return { data, error: null };
    } catch (error) {
      return {
        status: 'failed',
        error: error.message,
      };
    }
  }

  async getRealisasiOpdPbj(type, tahun) {
    try {
      await this.loadsettingApi()
      const { status, data } = await axios.post(`${BASE_URL}/sipedal/getrealisasiopd`, {
        tahun: tahun,
        jenis_transaksi: type,
      }, HEADER_API_KEY);
      if (status === 200) return { data, error: null };
    } catch (error) {
      return {
        status: 'failed',
        error: error.message,
      };
    }
  }
  async getDetailPaketOpdPbj(idsatker, tahun) {
    try {
      await this.loadsettingApi()
      let body = {
        tahun: tahun,
        idsatker,
      };
      const { status, data } = await axios.post(`${BASE_URL}/sipedal/getrealisasipaket`, body, HEADER_API_KEY);
      if (status === 200) return { data, error: null };
    } catch (error) {
      return {
        status: 'failed',
        error: error.message,
      };
    }
  }

  async getDataHibah(tahun) {
    try {
      await this.loadsettingApi()
      const { status, data } = await axios.get(`${BASE_URL}/hibah/getdatahibah/${tahun}`, HEADER_API_KEY);
      if (status === 200) return { data, error: null };
    } catch (error) {
      return {
        status: 'failed',
        error: error.message,
      };
    }
  }
  async getOpdPemberiHibah(tahun) {
    try {
      await this.loadsettingApi()
      const { status, data } = await axios.get(`${BASE_URL}/hibah/getopdpemberihibah/${tahun}`, HEADER_API_KEY);
      if (status === 200) return { data, error: null };
    } catch (error) {
      return {
        status: 'failed',
        error: error.message,
      };
    }
  }
  async getDetailHibahOpd(idOpd, tahun) {
    try {
      await this.loadsettingApi()
      const { status, data } = await axios.get(`${BASE_URL}/hibah/getdetailhibahopd/${idOpd}/${tahun}`, HEADER_API_KEY);
      if (status === 200) return { data, error: null };
    } catch (error) {
      return {
        status: 'failed',
        error: error.message,
      };
    }
  }

  async getListOpdIKD() {
    try {
      await this.loadsettingApi()
      const { status, data } = await axios.get(`${BASE_URL}/indikator/getlistopd`, HEADER_API_KEY);
      if (status === 200) return { data, error: null };
    } catch (error) {
      return {
        status: 'failed',
        error: error.message,
      };
    }
  }
  async getDataIkdOpd(idopd) {
    try {
      await this.loadsettingApi()
      const { status, data } = await axios.get(`${BASE_URL}/indikator/getikd/${idopd}`, HEADER_API_KEY);
      if (status === 200) return { data, error: null };
    } catch (error) {
      return {
        status: 'failed',
        error: error.message,
      };
    }
  }

  async getLayananIKP() {
    try {
      await this.loadsettingApi()
      const { status, data } = await axios.get(`${BASE_URL}/indikator/getlayananikp`, HEADER_API_KEY);
      if (status === 200) return { data, error: null };
    } catch (error) {
      return {
        status: 'failed',
        error: error.message,
      };
    }
  }
  
  async getDataIKP(idurusan) {
    try {
      await this.loadsettingApi()
      const { status, data } = await axios.get(`${BASE_URL}/indikator/getikp/${idurusan}`, HEADER_API_KEY);
      if (status === 200) return { data, error: null };
    } catch (error) {
      return {
        status: 'failed',
        error: error.message,
      };
    }
  }


  async getProgramUnggulan() {
    try {
      await this.loadsettingApi()
      const { status, data } = await axios.get(`${BASE_URL}/indikator/getprogul`,HEADER_API_KEY);
      if (status === 200) return { data, error: null };
    } catch (error) {
      return {
        status: 'failed',
        error: error.message,
      };
    }
  }
  async getDataProgul(idprogul) {
    try {
      await this.loadsettingApi()
      const { status, data } = await axios.get(`${BASE_URL}/indikator/getdataprogul/${idprogul}`, HEADER_API_KEY);
      if (status === 200) return { data, error: null };
    } catch (error) {
      return {
        status: 'failed',
        error: error.message,
      };
    }
  }

  async getListOPDPerjanjianKinerja() {
    try {
      const { status, data } = await axios.get(`https://sakip.sumbarprov.go.id/mobile/data/getDataOPD`);
      if (status === 200) return { data, error: null };
    } catch (error) {
      return {
        status: 'failed',
        error: error.message,
      };
    }
  }
  async getCapaianPerjanjianKinerja(idopd, tanggal) {
    try {
      tanggal = tanggal != "" ? `&tanggal=${tanggal}` : ""
      const { status, data } = await axios.get(`https://sakip.sumbarprov.go.id/mobile/data/getLaporanRealisasiOpd?id_opd=${idopd+tanggal}`);
      console.log(data);
      if (status === 200) return { data, error: null };
    } catch (error) {
      return {
        status: 'failed',
        error: error.message,
      };
    }
  }

  async getIndikatorKinerjaUtama() {
    try {
      await this.loadsettingApi()
      const { status, data } = await axios.get(`${BASE_URL}/indikator/getiku`, HEADER_API_KEY);
      if (status === 200) return { data, error: null };
    } catch (error) {
      return {
        status: 'failed',
        error: error.message,
      };
    }
  }

  async getStatsVisitors(){
    try {
      const { status, data } = await axios.get(`${INTERNAL_URL}/visitors/counter`);
      if (status === 200) return { data, error: null };
    } catch (error) {
      return {
        status: 'failed',
        error: error.message,
      };
    }
  }

  async getCapaianPKGubernur(tahun = "") {
    try {
      tahun = tahun !== "" ? `?tahun=${tahun}` : ""
      const { status, data } = await axios.get(`https://sakip.sumbarprov.go.id/mobile/data/getRealisasiPKGubernur${tahun}`);
      if (status === 200) return { data, error: null };
    } catch (error) {
      return {
        status: 'failed',
        error: error.message,
      };
    }
  }

  async getGrafikPendapatanPerBulan(tahun = "")
  {
    try {
      const { status, data } = await axios.get(`${BASE_URL}/bapenda/getgrafikpendapatanperbulan/${tahun}`, HEADER_API_KEY)
      if(status === 200) return { data, error: null }
    } catch (error) {
      return {
        status: 'failed',
        error: error.message,
      };
    }
  }
  async getGrafikDetailPendapatanPerBulan(tahun = "")
  {
    try {
      const { status, data } = await axios.get(`${BASE_URL}/bapenda/getgrafikdetailpendapatanperbulan/${tahun}`, HEADER_API_KEY)
      if(status === 200) return { data, error: null }
    } catch (error) {
      return {
        status: 'failed',
        error: error.message,
      };
    }
  }

  async getDataPendapatanPerBulan(tahun = "")
  {
    try {
      const APIKEY = {
        headers: {
          "Bapenda-key": this.bappendaKey,
          "Content-Type": "application/json"
        }
      }
      const { status, data } = await axios.get(`${bapendaForwardReqUrl}/GetPerbulan/${tahun}`, APIKEY)
      if(status === 200) return { data, error: null }
    } catch (error) {
      return {
        status: 'failed',
        error: error.message,
      };
    }
  }

  async getDataDetailPendapatanPerBulan(tahun = "", bulan = "")
  {
    try {
      const APIKEY = {
        headers: {
          "Bapenda-key": this.bappendaKey,
          "Content-Type": "application/json"
        }
      }
      const { status, data } = await axios.get(`${bapendaForwardReqUrl}/RincianBulan/${tahun}/${bulan}`, APIKEY)
      if(status === 200) return { data, error: null }
    } catch (error) {
      return {
        status: 'failed',
        error: error.message,
      };
    }
  }

}

export default Api;
