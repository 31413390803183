import React, { useEffect, memo, useState } from 'react'
import { connect } from 'react-redux';
import { compose } from 'redux';
import Layout from '../../../components/Layout';
import { useParams } from 'react-router-dom';
import { getDataIkdOpd_act } from '../../../../redux/ducks/ikd/actions'
import BackButton from '../../../components/BackButton';


const tahun = [2021,2022,2023,2024,2025,2026]
const DataIkd = (props) => {
    const { opdId, opdName, yearconfig } = useParams();
    const dataBreadcrumbs = [
        {
          title: 'Home',
          isActive: true,
          goTo: '/tahun/'+yearconfig,
        },
        {
          title: `Indikator Kinerja Daerah`,
          isActive: true,
          goTo: `/tahun/${yearconfig}/ikd/listopd`,
        },
    ]
    const [breadCrumbsData, setBreadCrumbsData] = useState();
    useEffect(() => {
        setBreadCrumbsData([...dataBreadcrumbs, {
            title: opdName,
            isActive: false,
            goTo: ''
        }])
    },[])

    useEffect(() => {
        props.getDataIkdOpd(opdId)
    },[])
    return (
        <Layout
            loading={props.dataIkd.loadingGet}
            breadCrumbsData={breadCrumbsData}
        >
            <div
                className="bg-white rounded-lg drop-shadow-2xl py-5 px-2 w-full"
            >
                <div className="flex-grow bg-white rounded-xl py-2 relative">
                    <h1 className="text-md text-center">Indikator Kinerja Daerah</h1>
                    <h1 className="mb-1 text-2xl font-bold text-center">
                    {opdName}
                    </h1>
                    <hr className="mb-3" />
                </div>
                <div className="w-full sm:overflow-visible overflow-x-auto flex flex-col justify-center">
                    <table className="w-full table-auto text-xs mb-4">
                        <thead className="bg-primaryGreen sticky top-0">
                            <tr>
                                <th className="border border-slate-500  w-[3%]" rowSpan={3}>
                                    No
                                </th>
                                <th className="border border-slate-500 md:w-1/4" rowSpan={3}>
                                    Indikator
                                </th>
                                <th className="border border-slate-500" colSpan={tahun.length * 2}>
                                    Tahun
                                </th>
                            </tr>
                            <tr>
                                {
                                    tahun.map((item) => {
                                        return (
                                            <th className="border border-slate-500" colSpan={2}>{item}</th>
                                        )
                                    })
                                }
                            </tr>
                            <tr>
                                {
                                    tahun.map((item) => {
                                        return (
                                            <>
                                                <th className="border border-slate-500">
                                                    <span className="md:block hidden">Target</span>
                                                    <span className="md:hidden block">T</span>
                                                </th>
                                                <th className="border border-slate-500">
                                                    <span className="md:block hidden">Capaian</span>
                                                    <span className="md:hidden block">C</span>
                                                </th>
                                            </>
                                        )
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                props.dataIkd ? props.dataIkd.data.map((item, keys) => {
                                    const indikator = renderIndikator(item.indikator)
                                    const urusan = (
                                        <>
                                            <tr key={keys}>
                                                <td className="border border-slate-500 font-bold py-2 px-5 bg-green-200" colSpan={14}>{item.urusan}</td>
                                            </tr>
                                            {
                                                indikator
                                            }
                                        </>
                                    )
                                    return (urusan)
                                }) : 
                                <tr>
                                    <td className="border border-slate-500 py-2 px-5 bg-green-200" colSpan={14}>Loading...</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                    <span className="text-red-400 text-xs font-bold">
                        Notes : <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; *) Data triwulan IV
                    </span>
                </div>
                <div className="w-full px-10">
                </div>
            </div>
            <BackButton backLink={`/tahun/${yearconfig}/ikd/listopd`} />
        </Layout>
    )
}

const renderIndikator = (list) => {
    return list?.map((val, keys) => {
        return (
            <tr key={keys}>
                <td className="border border-slate-500 p-2 text-center">{keys+1}</td>
                <td className="border border-slate-500 p-2">{val.indikator}</td>
                {
                    tahun.map((item) => {
                        return (
                            <>
                                <td className="border border-slate-500 p-2 text-center">{val[`target_${item}`]}</td>
                                <td className="border border-slate-500 p-2 text-center">{val[`capaian_${item}`]}</td>
                            </>
                        )
                    })
                }
            </tr>
        )
    })
}

const mapStateToProps = (state) => ({
    dataIkd: state.ikdState.dataIkd
})

const mapDispatchToProps = {
    getDataIkdOpd: getDataIkdOpd_act
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect, memo)(DataIkd)