import React from 'react'
import { useNavigate } from 'react-router-dom'

const BackButton = ({ backLink }) => {
    const navigate = useNavigate()
    return (
        <button onClick={() => navigate(backLink)} className="act-btn md:p-2 p-1 md:w-[47px] w-8 md:h-[47px] h-8">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="md:w-8 w-6 md:h-8 h-6 text-white">
            <path
                fill-rule="evenodd"
                d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-4.28 9.22a.75.75 0 000 1.06l3 3a.75.75 0 101.06-1.06l-1.72-1.72h5.69a.75.75 0 000-1.5h-5.69l1.72-1.72a.75.75 0 00-1.06-1.06l-3 3z"
                clip-rule="evenodd"
            />
            </svg>
        </button>
    )
}

export default BackButton