import { parseJwt } from '../../../app/helper';

export const types = {
  AUTH_LOADING: 'AUTH_LOADING',
  AUTH_SUCCESS: 'AUTH_SUCESSS',
  AUTH_FAILED: 'AUTH_FAILED',
  AUTH_DESTROY: 'AUTH_DESTROY',
};

export const refillAuth = () => async (dispatch, getState, Api) => {
  const token = localStorage.getItem('token');
  if (token !== null) {
    let biodata = parseJwt(token);
    dispatch({
      type: types.AUTH_SUCCESS,
      payload: {
        token: token,
        data: biodata,
      },
    });
  }
};

export const authDestroy = () => async (dispatch, getState, Api) => {
  console.log('eeeee logout');
  localStorage.removeItem('token');
  dispatch({
    type: types.AUTH_DESTROY,
  });
  window.location.reload();
};

export const authAction = (payload) => async (dispatch, getState, Api) => {
  dispatch({
    type: types.AUTH_LOADING,
  });
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Api.authentication(payload);
      if (response.status !== 'failed') {
        let token = response.data.token_access;
        let biodata = parseJwt(token);
        localStorage.setItem('token', token);
        dispatch({
          type: types.AUTH_SUCCESS,
          payload: {
            token: token,
            data: biodata,
          },
        });
        resolve({ code: 200 });
      } else {
        dispatch({
          type: types.AUTH_FAILED,
          payload: response.error,
        });
        reject({ code: 400 });
      }
    } catch (error) {
      console.log('eeeeeeerrrr', error);
      dispatch({
        type: types.AUTH_FAILED,
        payload: 'error',
      });
      reject({ code: 401 });
    }
  });
};
