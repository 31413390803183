import * as types from "./types"

const initialState = {
    CapaianKinerjaGubernur: {
        loadingGet: false,
        errorGet: false,
        messageGet: "",
        data: [],
    }
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case types.GET_CPK_GUBERNUR_START:
            return {
                ...state,
                CapaianKinerjaGubernur: {
                    ...state.CapaianKinerjaGubernur,
                    loadingGet: true,
                    errorGet: false,
                    messageGet: ""
                }
            }
        case types.GET_CPK_GUBERNUR_SUCCESS:
            return {
                ...state,
                CapaianKinerjaGubernur: {
                    ...state.CapaianKinerjaGubernur,
                    loadingGet: false,
                    data: action.payload.data
                }
            }

        case types.GET_CPK_GUBERNUR_FAILED:
            return {
                ...state,
                CapaianKinerjaGubernur: {
                    ...state.CapaianKinerjaGubernur,
                    loadingGet: false,
                    errorGet: true,
                    messageGet: action.payload.message
                }
            }

        default:
            return state
    }
}

