import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import VisitorsCounter from '../VisitorsCounter'
import LaporIcon from '../LaporIcon'

const YearSelector = () => {
    const navigate = useNavigate()
    const { yearconfig } = useParams()
    const [ selectedYear, setSelectedYear ] = useState('')
    useEffect(() => {
        setSelectedYear(yearconfig)
    },[yearconfig])
    const changeYearHandler = (e) => {
        navigate('/tahun/'+e.target.value)
    }
    const [time, setTime] = useState(new Date());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTime(new Date())
        }, 1000)

        // Membersihkan interval saat komponen unmount
        return () => clearInterval(intervalId);
    },[])

    const days = time.getDay();
    const months = time.getMonth();
    const years = time.getFullYear();
    const dates = time.getDate()
    const hours = time.getHours();
    const minutes = time.getMinutes();
    const seconds = time.getSeconds();

    const hari = ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu"]
    const month = ["Januari","Febuari","Maret","April","Mei","Juni","Juli","Agustus","September","Oktober","November","Desember"];
    return (
        <div className="w-full bg-white drop-shadow-xl rounded-lg sm:px-5 px-3 flex sm:flex-row flex-col sm:gap-4 sm:justify-between">
          <div className="sm:py-2 pt-2 w-full lg:w-[30%] xl:w-[30%] flex items-center sm:justify-start justify-center">
            <div className="lg:text-2xl md:text-xl sm:text-lg text-md font-bold text-center sm:text-left text-primaryGreen">
              <span id="days">{hari[days]}</span>, <span id="date">{dates} {month[months]} {years}</span> &nbsp;
              <span id="hours">{hours < 10 ? '0' + hours : hours}</span>:<span id="minutes">{minutes < 10 ? '0' + minutes : minutes}</span>:<span id="seconds">{seconds < 10 ? '0' + seconds : seconds}</span>
            </div>
          </div>
          <div className="hidden md:block">
            <LaporIcon />
          </div>
          <div className="sm:py-2 pb-4 w-full lg:w-[30%] xl:w-[30%] flex flex-row gap-2 items-center">
            <label for="table-search" className="font-bold hidden sm:block whitespace-nowrap">
              Data Tahun
            </label>
            <div className="relative w-full">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                  className="w-5 h-5 text-gray-500">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
                </svg>
              </div>
              <select
                value={selectedYear}
                className="bg-gray-50 border border-gray-300 text-gray-800 sm:text-md text-sm font-bold rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-1.5 placeholder:text-gray-900"
                onChange={changeYearHandler}
                >
                <option value="2024">2024</option>
                <option value="2023">2023</option>
                <option value="2022">2022</option>
              </select>
            </div>
          </div>
        </div>
    )
}

export default YearSelector