const GET_CPK_GUBERNUR_START = "GET_CPK_GUBERNUR_START"
const GET_CPK_GUBERNUR_SUCCESS = "GET_CPK_GUBERNUR_SUCCESS"
const GET_CPK_GUBERNUR_FAILED = "GET_CPK_GUBERNUR_FAILED"

export {
    GET_CPK_GUBERNUR_START,
    GET_CPK_GUBERNUR_SUCCESS,
    GET_CPK_GUBERNUR_FAILED
}

