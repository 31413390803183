import * as types from "./types"

const getProgramUnggulan_act = () => async (dispatch, getState, Api) => {
    dispatch({ type: types.GET_PROGRAM_UNGGULAN_START })
    const response = await Api.getProgramUnggulan();
    if (response.status !== "failed") {
        dispatch({
            type: types.GET_PROGRAM_UNGGULAN_SUCCESS,
            payload: {
                data: response.data.result.data,
                timestamp: response.data.timestamp,
                message: response.data.result.message,
                author: response.data.author
            },
        });
    } else {
        dispatch({
            type: types.GET_PROGRAM_UNGGULAN_FAILED,
            payload: {
                message: response.error,
            },
        });
    }
}

const getDataProgul_act = (idprogul) => async (dispatch, getState, Api) => {
    dispatch({ type: types.GET_DATA_PROGUL_START })
    const response = await Api.getDataProgul(idprogul);
    if (response.status !== "failed") {
        dispatch({
            type: types.GET_DATA_PROGUL_SUCCESS,
            payload: {
                data: response.data.result.data,
                timestamp: response.data.timestamp,
                message: response.data.result.message,
                author: response.data.author
            },
        });
    } else {
        dispatch({
            type: types.GET_DATA_PROGUL_FAILED,
            payload: {
                message: response.error,
            },
        });
    }
}

export {
    getProgramUnggulan_act,
    getDataProgul_act
}