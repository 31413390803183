import * as types from './types'

const initialState = {
    programUnggulan: {
        loadingGet: false,
        errorGet: false,
        messageGet: "",
        data: [],
        lastUpdated: "",
        author: ""
    },
    dataProgul: {
        loadingGet: false,
        errorGet: false,
        messageGet: "",
        data: [],
        lastUpdated: "",
        author: ""
    },
}

export default function progulReducer(state = initialState, actions){
    switch(actions.type){
        case types.GET_PROGRAM_UNGGULAN_START:
            return {
                ...state,
                programUnggulan:{
                    ...state.programUnggulan,
                    loadingGet: true,
                    errorGet: false,
                    messageGet: "loading"
                }
            }

        case types.GET_PROGRAM_UNGGULAN_SUCCESS:
            return {
                ...state,
                programUnggulan:{
                    ...state.programUnggulan,
                    loadingGet: false,
                    errorGet: false,
                    messageGet: actions.payload.message,
                    data: actions.payload.data,
                    lastUpdated: actions.payload.timestamp,
                    author: actions.payload.author
                }
            }
        case types.GET_PROGRAM_UNGGULAN_FAILED:
            return {
                ...state,
                programUnggulan:{
                    ...state.programUnggulan,
                    loadingGet: false,
                    errorGet: true,
                    messageGet: actions.payload.message,
                }
            }
        case types.GET_DATA_PROGUL_START:
            return {
                ...state,
                dataProgul:{
                    ...state.dataProgul,
                    loadingGet: true,
                    errorGet: false,
                    messageGet: "loading"
                }
            }

        case types.GET_DATA_PROGUL_SUCCESS:
            return {
                ...state,
                dataProgul:{
                    ...state.dataProgul,
                    loadingGet: false,
                    errorGet: false,
                    messageGet: actions.payload.message,
                    data: actions.payload.data,
                    lastUpdated: actions.payload.timestamp,
                    author: actions.payload.author
                }
            }
        case types.GET_DATA_PROGUL_FAILED:
            return {
                ...state,
                dataProgul:{
                    ...state.dataProgul,
                    loadingGet: false,
                    errorGet: true,
                    messageGet: actions.payload.message,
                }
            }
        
        default:
            return state;
    }
}