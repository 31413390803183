import * as types from './types'

const initialState = {
    layananIkp: {
        loadingGet: false,
        errorGet: false,
        messageGet: "",
        data: [],
        lastUpdated: "",
        author: ""
    },
    dataIkp: {
        loadingGet: false,
        errorGet: false,
        messageGet: "",
        data: [],
        lastUpdated: "",
        author: ""
    },
}

export default function ikpReducer(state = initialState, actions){
    switch(actions.type){
        case types.GET_LAYANAN_IKP_START:
            return {
                ...state,
                layananIkp:{
                    ...state.layananIkp,
                    loadingGet: true,
                    errorGet: false,
                    messageGet: "loading"
                }
            }
        case types.GET_LAYANAN_IKP_SUCCESS:
            return {
                ...state,
                layananIkp:{
                    ...state.layananIkp,
                    loadingGet: false,
                    errorGet: false,
                    messageGet: actions.payload.message,
                    data: actions.payload.data,
                    lastUpdated: actions.payload.timestamp,
                    author: actions.payload.author
                }
            }
        case types.GET_LAYANAN_IKP_FAILED:
            return {
                ...state,
                layananIkp:{
                    ...state.layananIkp,
                    loadingGet: false,
                    errorGet: true,
                    messageGet: actions.payload.message,
                }
            }
        case types.GET_DATA_IKP_START:
            return {
                ...state,
                dataIkp:{
                    ...state.dataIkp,
                    loadingGet: true,
                    errorGet: false,
                    messageGet: "loading"
                }
            }
        case types.GET_DATA_IKP_SUCCESS:
            return {
                ...state,
                dataIkp:{
                    ...state.dataIkp,
                    loadingGet: false,
                    errorGet: false,
                    messageGet: actions.payload.message,
                    data: actions.payload.data,
                    lastUpdated: actions.payload.timestamp,
                    author: actions.payload.author
                }
            }
        case types.GET_DATA_IKP_FAILED:
            return {
                ...state,
                dataIkp:{
                    ...state.dataIkp,
                    loadingGet: false,
                    errorGet: true,
                    messageGet: actions.payload.message,
                }
            }
        
        default:
            return state;
    }
}