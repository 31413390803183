import React, { useEffect, memo } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getRealisasiBelanjaOPD, getPendapatanDaerah } from '../../../redux/ducks/home/actions';
import Layout from '../../components/Layout';
import { numberFormatter } from '../../helper';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Pie } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';

ChartJS.register(ArcElement, Tooltip, Legend);

const Home2 = ({ getRealisasiBelanjaOPD, getPendapatanDaerah, homeState }) => {
  const navigate = useNavigate();
  useEffect(() => {
    getRealisasiBelanjaOPD();
    getPendapatanDaerah();
  }, [getRealisasiBelanjaOPD.getPendapatanDaerah]);

  const dataPendapatan = {
    labels: ['Target', 'direalisasikan'],
    datasets: [
      {
        label: 'Rp. ',
        data: [homeState?.pendapatanDaerah?.target, homeState?.pendapatanDaerah?.realisasi],
        backgroundColor: ['rgb(255,159,64)', 'rgb(255,64,105)'],
        // borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)'],
        borderWidth: 1,
      },
    ],
  };

  const dataRealisasiBelanjaOpd = {
    labels: ['Target', 'direalisasikan'],
    datasets: [
      {
        label: 'Rp. ',
        data: [homeState?.realisasiBelanjaOPD?.target, homeState?.realisasiBelanjaOPD?.realisasi],
        backgroundColor: ['rgb(54,162,235)', 'rgb(255,64,105)'],
        // borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)'],
        borderWidth: 1,
      },
    ],
  };

  const _toBappendDetail = () => navigate('/bappenda/detail-pendapatan');

  const _toSimbangdaDetail = () => navigate('/simbangda/detail-belanja-opd');

  return (
    <Layout loading={homeState.pendapatanDaerah.loadingGet}>
      <div className="flex flex-col md:flex-col lg:flex-row  xl:flex-row gap-5">
        <div className="w-full lg:w-8/12 xl:w-8/12 space-y-5 bg-white rounded-xl px-5 py-5 pb-10 ">
          <div onClick={_toBappendDetail} className="bg-primeblue rounded-xl drop-shadow-2xl p-5 cursor-pointer h-1/2">
            <div className="flex flex-col md:flex-row lg:flex-row py-10 px-2">
              <div className=" lg:w-3/5 xl:w-3/5">
                <h1 className="text-2xl text-white text-left mb-5">Pendapatan Daerah</h1>
                <h2 className="2xl:text-xl xl:text-xl text-sm text-white flex flex-row ">
                  <span className="w-1/3">Target</span>
                  <span>: Rp. {numberFormatter(homeState.pendapatanDaerah.target)}</span>
                </h2>
                <h2 className="2xl:text-xl xl:text-xl text-sm text-white flex flex-row mt-2">
                  <span className="w-1/3">Realisasi</span>
                  <span>: Rp. {numberFormatter(homeState.pendapatanDaerah.realisasi)}</span>
                </h2>
                <h2 className="2xl:text-xl xl:text-xl text-sm text-white flex flex-row mt-2">
                  <span className="w-1/3">Persentase</span>
                  <span>: {homeState.pendapatanDaerah.persentase} %</span>
                </h2>
              </div>
              {/* <div className='w-full lg:w-60 lg:h-60  xl:w-60 xl:h-60'> */}
              <div className="w-full lg:w-72 lg:h-56  xl:w-72 xl:h-56">
                <Pie
                  data={dataPendapatan}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    color: 'black',
                    plugins: {
                      legend: {
                        labels: {
                          font: {
                            size: 14,
                          },
                        },
                      },
                    },
                  }}
                />
              </div>
            </div>
          </div>

          <div
            onClick={_toSimbangdaDetail}
            className="bg-primegreen rounded-xl drop-shadow-2xl p-5 cursor-pointer h-1/2 "
          >
            <div className="flex flex-col md:flex-row lg:flex-row py-10 px-2 ">
              <div className=" lg:w-3/5 xl:w-3/5">
                <h1 className="text-2xl text-white text-left mb-5">Realisasi Belanja OPD</h1>
                <h2 className="2xl:text-xl xl:text-xl text-sm text-white flex flex-row ">
                  <span className="w-1/3">PAGU APBD</span>
                  <span>: Rp. {numberFormatter(homeState.realisasiBelanjaOPD.target)}</span>
                </h2>
                <h2 className="2xl:text-xl xl:text-xl text-sm text-white flex flex-row mt-2">
                  <span className="w-1/3">Realisasi</span>
                  <span>: Rp. {numberFormatter(homeState.realisasiBelanjaOPD.realisasi)}</span>
                </h2>
                <h2 className="2xl:text-xl xl:text-xl text-sm text-white flex flex-row mt-2">
                  <span className="w-1/3">Persentase</span>
                  <span>: {homeState.realisasiBelanjaOPD.persentase} %</span>
                </h2>
              </div>
              <div className="w-full lg:w-72 lg:h-56  xl:w-72 xl:h-56">
                <Pie
                  data={dataRealisasiBelanjaOpd}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    color: 'black',
                    plugins: {
                      legend: {
                        labels: {
                          font: {
                            size: 14,
                            // weight: 'bold',
                          },
                        },
                      },
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-2/5 xl:w-2/5 py-8">
          <div className="grid grid-rows-3 gap-5">
            <div className=" bg-white rounded-xl drop-shadow-2xl">
              <div className="w-full py-4 px-2 bg-primeblue rounded-t-xl">
                <h1 className="text-xl font-bold text-white text-center">E-Purchasing</h1>
              </div>
              <div className="py-1 px-2">
                <ul>
                  <li>
                    <div className={`flex flex-col lg:flex-row xl:flex-row px-2 `}>
                      <div className="w-[60%] font-medium">Paket</div>
                      <div className="w-[50%]">
                        <span className={` text-right rounded-lg float-right`}>{numberFormatter(1000000000)}</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className={`flex flex-col lg:flex-row xl:flex-row px-2 `}>
                      <div className="w-[60%] font-medium">Nilai</div>
                      <div className="w-[50%]">
                        <span className={` text-right rounded-lg float-right`}>{numberFormatter(1000000000)}</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className={`flex flex-col lg:flex-row xl:flex-row px-2 `}>
                      <div className="w-[60%] font-medium">Belum Proses</div>
                      <div className="w-[50%]">
                        <span className={` text-right rounded-lg float-right`}>{numberFormatter(1000000000)}</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className={`flex flex-col lg:flex-row xl:flex-row px-2 `}>
                      <div className="w-[60%] font-medium">Sedang Proses</div>
                      <div className="w-[50%]">
                        <span className={` text-right rounded-lg float-right`}>{numberFormatter(1000000000)}</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className={`flex flex-col lg:flex-row xl:flex-row px-2 `}>
                      <div className="w-[60%] font-medium">Selesai Proses</div>
                      <div className="w-[50%]">
                        <span className={` text-right rounded-lg float-right`}>{numberFormatter(1000000000)}</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className={`flex flex-col lg:flex-row xl:flex-row px-2 `}>
                      <div className="w-[60%] font-medium">Selesai Pelaksanaan</div>
                      <div className="w-[50%]">
                        <span className={` text-right rounded-lg float-right`}>{numberFormatter(1000000000)}</span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className=" bg-white rounded-xl drop-shadow-2xl">
              <div className="w-full py-4 px-2 bg-primeblue rounded-t-xl">
                <h1 className="text-xl font-bold text-white text-center">Tendering</h1>
              </div>
              <div className="py-1 px-2">
                <ul>
                  <li>
                    <div className={`flex flex-col lg:flex-row xl:flex-row px-2 `}>
                      <div className="w-[60%] font-medium">Paket</div>
                      <div className="w-[50%]">
                        <span className={` text-right rounded-lg float-right`}>{numberFormatter(1000000000)}</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className={`flex flex-col lg:flex-row xl:flex-row px-2 `}>
                      <div className="w-[60%] font-medium">Nilai</div>
                      <div className="w-[50%]">
                        <span className={` text-right rounded-lg float-right`}>{numberFormatter(1000000000)}</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className={`flex flex-col lg:flex-row xl:flex-row px-2 `}>
                      <div className="w-[60%] font-medium">Belum Proses</div>
                      <div className="w-[50%]">
                        <span className={` text-right rounded-lg float-right`}>{numberFormatter(1000000000)}</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className={`flex flex-col lg:flex-row xl:flex-row px-2 `}>
                      <div className="w-[60%] font-medium">Sedang Proses</div>
                      <div className="w-[50%]">
                        <span className={` text-right rounded-lg float-right`}>{numberFormatter(1000000000)}</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className={`flex flex-col lg:flex-row xl:flex-row px-2 `}>
                      <div className="w-[60%] font-medium">Selesai Proses</div>
                      <div className="w-[50%]">
                        <span className={` text-right rounded-lg float-right`}>{numberFormatter(1000000000)}</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className={`flex flex-col lg:flex-row xl:flex-row px-2 `}>
                      <div className="w-[60%] font-medium">Selesai Pelaksanaan</div>
                      <div className="w-[50%]">
                        <span className={` text-right rounded-lg float-right`}>{numberFormatter(1000000000)}</span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className=" bg-white rounded-xl drop-shadow-2xl">
              <div className="w-full py-4 px-2 bg-primegreen rounded-t-xl">
                <h1 className="text-xl font-bold text-white text-center">Non Tenderig dan Non E-Purchasing</h1>
              </div>
              <div className="py-1 px-2">
                <ul>
                  <li>
                    <div className={`flex flex-col lg:flex-row xl:flex-row px-2 `}>
                      <div className="w-[60%] font-medium">Paket</div>
                      <div className="w-[50%]">
                        <span className={` text-right rounded-lg float-right`}>{numberFormatter(1000000000)}</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className={`flex flex-col lg:flex-row xl:flex-row px-2 `}>
                      <div className="w-[60%] font-medium">Nilai</div>
                      <div className="w-[50%]">
                        <span className={` text-right rounded-lg float-right`}>{numberFormatter(1000000000)}</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className={`flex flex-col lg:flex-row xl:flex-row px-2 `}>
                      <div className="w-[60%] font-medium">Belum Proses</div>
                      <div className="w-[50%]">
                        <span className={` text-right rounded-lg float-right`}>{numberFormatter(1000000000)}</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className={`flex flex-col lg:flex-row xl:flex-row px-2 `}>
                      <div className="w-[60%] font-medium">Sedang Proses</div>
                      <div className="w-[50%]">
                        <span className={` text-right rounded-lg float-right`}>{numberFormatter(1000000000)}</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className={`flex flex-col lg:flex-row xl:flex-row px-2 `}>
                      <div className="w-[60%] font-medium">Selesai Proses</div>
                      <div className="w-[50%]">
                        <span className={` text-right rounded-lg float-right`}>{numberFormatter(1000000000)}</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className={`flex flex-col lg:flex-row xl:flex-row px-2 `}>
                      <div className="w-[60%] font-medium">Selesai Pelaksanaan</div>
                      <div className="w-[50%]">
                        <span className={` text-right rounded-lg float-right`}>{numberFormatter(1000000000)}</span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            {/* <div className="h-64 rounded-xl drop-shadow-2xl bg-white">
                <div className="w-full py-4 bg-primegreen rounded-t-xl">
                  <h1 className="text-2xl text-white text-center">Hibah</h1>
                </div>
              </div> */}
          </div>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  homeState: state.homeState,
});

const mapDispatchToProps = {
  getRealisasiBelanjaOPD,
  getPendapatanDaerah,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(Home2);
