import React from 'react'
import { numberFormatter } from '../helper'

const RankingOpdByDeviasi = ({data = []}) => {
    const [ top5Opd, setTop5Opd ] = React.useState([])
    const [ worst5Opd, setWorst5Opd ] = React.useState([])

    React.useEffect(() => {
        let temptop5Opd = []
        let tempworst5Opd = []
        const dataOpd = data
        const ReverseDataOpd = []
        let n = 1
        dataOpd.forEach(element => {
            if(n <= 5){
            temptop5Opd.push({
                no: n,
                opd: element.singkatan_skpd,
                nilai: element.deviasi_realisasi_keuangan
            })
            }
            n++
            ReverseDataOpd.push(element)
        })
        n = 1
        ReverseDataOpd.reverse().forEach(element => {
            if(n <= 5){
            tempworst5Opd.push({
                no: n,
                opd: element.singkatan_skpd,
                nilai: element.deviasi_realisasi_keuangan
            })
            }
            n++
        })
        setTop5Opd(temptop5Opd)
        setWorst5Opd(tempworst5Opd)
    },[data])
return (

<div className="w-full grid md:grid-cols-2 gap-5">

    <div className="bg-white p-5 rounded-md mt-5">
        <h1 className="text-xl font-bold text-center">
            5 OPD dengan Realisasi Kinerja Tertinggi
        </h1>
        <h3 className="text-slate-500 text-center">
            sampai dengan hari ini
        </h3>
        <table className="w-full">
            <thead>
                <tr className='border-b-2 border-slate-200 mb-5'>
                    <th className="w-10 p-2">No.</th>
                    <th className="w-2/3 p-2 text-left">OPD</th>
                    <th className='text-right p-2'>Deviasi</th>
                </tr>
            </thead>
            <tbody>
                {
                top5Opd.map((value,i) => {
                return (
                <tr key={i} className='border-b border-dashed'>
                    <td className='text-center pt-2 pb-1'>{value.no}</td>
                    <td className='pt-2 pb-1'>{value.opd}</td>
                    <td className='text-right pt-2 pb-1'>{numberFormatter(value.nilai)} %</td>
                </tr>
                )
                })
                }
            </tbody>
        </table>
    </div>
    <div className="bg-white p-5 rounded-md mt-5">
        <h1 className="text-xl font-bold text-center">
            5 OPD dengan Realisasi Kinerja Terendah
        </h1>
        <h3 className="text-slate-500 text-center">
            sampai dengan hari ini
        </h3>
        <table className="w-full">
            <thead>
                <tr className='border-b-2 border-slate-200 mb-5'>
                    <th className="w-10 p-2">No.</th>
                    <th className="w-2/3 p-2 text-left">OPD</th>
                    <th className='text-right p-2'>Deviasi</th>
                </tr>
            </thead>
            <tbody>
                {
                worst5Opd.map((value,i) => {
                return (
                <tr key={i} className='border-b border-dashed'>
                    <td className='text-center pt-2 pb-1'>{value.no}</td>
                    <td className='pt-2 pb-1'>{value.opd}</td>
                    <td className='text-right pt-2 pb-1'>{numberFormatter(value.nilai)} %</td>
                </tr>
                )
                })
                }
            </tbody>
        </table>
    </div>

</div>
)
}

export default RankingOpdByDeviasi