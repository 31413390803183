import * as types from './types'

const initialState = {
    dataIku: {
        loadingGet: false,
        errorGet: false,
        messageGet: "",
        data: [],
        lastUpdated: "",
        author: ""
    }
}

export default function ikuReducer(state = initialState, actions){
    switch(actions.type){
        case types.GET_IKU_START:
            return{
                ...state,
                dataIku:{
                    ...state.dataIku,
                    loadingGet: true,
                    errorGet: false,
                    messageGet: 'loading...'
                }
            }
        
        case types.GET_IKU_SUCCESS:
            return {
                ...state,
                dataIku: {
                    ...state.dataIku,
                    loadingGet: false,
                    errorGet: false,
                    messageGet: actions.payload.message,
                    data: actions.payload.data,
                    lastUpdated: actions.payload.timestamp,
                    author: actions.payload.author
                }
            }

        case types.GET_IKU_FAILED:
            return {
                ...state,
                dataIku: {
                    ...state.dataIku,
                    loadingGet: false,
                    errorGet: true,
                    messageGet: actions.payload.message
                }
            }

        default:
            return state;
    }
}