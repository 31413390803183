import * as types from './types'

const getLayananIkd_act = () => async (dispatch, getState, Api) => {
    dispatch({ type: types.GET_LAYANAN_IKP_START })
    const response = await Api.getLayananIKP();
    if (response.status !== "failed") {
        dispatch({
            type: types.GET_LAYANAN_IKP_SUCCESS,
            payload: {
                data: response.data.result.data,
                timestamp: response.data.timestamp,
                message: response.data.result.message,
                author: response.data.author
            },
        });
    } else {
        dispatch({
            type: types.GET_LAYANAN_IKP_FAILED,
            payload: {
                message: response.error,
            },
        });
    }
}

const getDataIkpd_act = (idurusan) => async (dispatch, getState, Api) => {
    dispatch({ type: types.GET_DATA_IKP_START })
    const response = await Api.getDataIKP(idurusan)
    if (response.status !== "failed") {
        dispatch({
            type: types.GET_DATA_IKP_SUCCESS,
            payload: {
                data: response.data.result.data,
                timestamp: response.data.timestamp,
                message: response.data.result.message,
                author: response.data.author
            },
        });
    } else {
        dispatch({
            type: types.GET_DATA_IKP_FAILED,
            payload: {
                message: response.error,
            },
        });
    }
}

export {
    getLayananIkd_act,
    getDataIkpd_act
}