import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { authAction } from '../../../redux/ducks/auth/action';
import WithIsPublic from '../../hoc/WithIsPublic';

const LoginPage = (props) => {
  const [formValue, setFormValue] = React.useState({
    username: '',
    password: '',
  });

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await props.disburseAuth(formValue);
      navigate('/');
    } catch (error) {
      console.log('resposn api in components errr', error);
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-[#F2F9F1] ">
      <div className="flex flex-col bg-white shadow-md px-4 sm:px-6 md:px-8 lg:px-10 py-8 rounded-xl w-full max-w-md">
        <div className="font-bold text-center  text-2xl text-primaryGreen">
          Dashboard Pembagunan <br /> <span className="text-red-600">Sumatera Barat</span>
        </div>
        {props.error && (
          <div className="bg-red-100 p-3 rounded-md mt-2">
            <p className="text-red-500 text-sm">{props.message}</p>
          </div>
        )}
        <div className="mt-5">
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col mb-6">
              <label for="email" className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600">
                Username
              </label>
              <div className="relative">
                <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-700">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
                    <path
                      fillRule="evenodd"
                      d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>

                <input
                  type="text"
                  onChange={(e) =>
                    setFormValue((state) => ({
                      ...state,
                      username: e.target.value,
                    }))
                  }
                  value={formValue.username}
                  className="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-orange-400"
                  placeholder="Nip"
                />
              </div>
            </div>
            <div className="flex flex-col mb-6">
              <label for="password" className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600">
                Password
              </label>
              <div className="relative">
                <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-700">
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
                      <path
                        fillRule="evenodd"
                        d="M12 1.5a5.25 5.25 0 00-5.25 5.25v3a3 3 0 00-3 3v6.75a3 3 0 003 3h10.5a3 3 0 003-3v-6.75a3 3 0 00-3-3v-3c0-2.9-2.35-5.25-5.25-5.25zm3.75 8.25v-3a3.75 3.75 0 10-7.5 0v3h7.5z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </span>
                </div>

                <input
                  type="password"
                  onChange={(e) =>
                    setFormValue((state) => ({
                      ...state,
                      password: e.target.value,
                    }))
                  }
                  value={formValue.password}
                  className="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-orange-400"
                  placeholder="Password"
                />
              </div>
            </div>

            {/* <div className="flex items-center mb-6 -mt-4">
              <div className="flex ml-auto">
                <a href="#" className="inline-flex text-xs sm:text-sm text-blue-500 hover:text-blue-700">
                  Forgot Your Password?
                </a>
              </div>
            </div> */}

            <div className="flex w-full">
              {props.loading ? (
                <p className="text-red-500 mx-auto">Mohon menunggu...</p>
              ) : (
                <button
                  type="submit"
                  className="flex items-center justify-center focus:outline-none text-white text-sm sm:text-base bg-primaryGreen  rounded py-2 w-full transition duration-150 ease-in"
                >
                  <span className="mr-2">Masuk</span>
                  <span>
                    <svg
                      className="h-4 w-4"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </span>
                </button>
              )}
            </div>
          </form>
        </div>
        <div className="flex justify-center items-center mt-6">
          <Link to={'/'} className="inline-flex items-center font-bold rounded-md px-2 text-sm text-center">
            <span className="ml-2 text-orange-800 hover:text-red-400">Kembali ke halaman utama</span>
          </Link>
        </div>
        <div className="flex justify-center items-center mt-6">
          <a
            href="#"
            target="_blank"
            className="inline-flex items-center font-bold text-blue-500 hover:text-blue-700 text-xs text-center"
          >
            <span className="ml-2 text-gray-800">
              Pemerintah Provinsi Sumatera Barat <br /> <span className="text-primaryGreen">Dashboard Pembagunan </span>
              v.0.1.0
            </span>
          </a>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state.authState.loading,
  message: state.authState.message,
  error: state.authState.error,
});

const mapDispatchToProps = {
  disburseAuth: authAction,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(WithIsPublic(LoginPage));
